// i18n-react.js
import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';

import deLocale from "../../locales/de.json";
import enLocale from "../../locales/en.json";
import esLocale from "../../locales/es.json";
import frLocale from "../../locales/fr.json";
import hiLocale from "../../locales/hi.json";
import itLocale from "../../locales/it.json";
import ptLocale from "../../locales/pt.json";
import ruLocale from "../../locales/ru.json";
import ukLocale from "../../locales/uk.json";
import zhLocale from "../../locales/zh.json";

const languageLocales = {
  de: { translation: deLocale },
  es: { translation: esLocale },
  en: { translation: enLocale },
  fr: { translation: frLocale },
  hi: { translation: hiLocale },
  it: { translation: itLocale },
  pt: { translation: ptLocale },
  ru: { translation: ruLocale },
  uk: { translation: ukLocale },
  zh: { translation: zhLocale },
};

i18n
  .use(reactI18nextModule)
  .init({
    resources: languageLocales,
    lng: 'pt',
    fallbackLng: 'pt'
  });

export default i18n;
