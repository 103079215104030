import React, { Component } from "react";
import { ThemeProvider } from "styled-components";
import detect from "detect.js";
import io from "socket.io-client";
import { withNamespaces } from 'react-i18next';
import { post } from "../../common/xhr";

import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';

import Form from './Form';
import Avatar from "./Avatar";
import Messages from "./Messages";

import { ChatHeader, Container, Slab, SlabTitle, SlabSubTitle, ActiveUsers, VideoChatContainer, LocalVideoControls, LocalVideoConfig, CallButtonContainer, BetaTag, UserHangUp, UserShare, SplitPanel } from "./StyledComponents";

import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faVideoSlash,
  faVolumeUp,
  faMicrophone,
  faDesktop,
  faPhoneSlash,
  faMicrophoneSlash,
  faCog,
  faTimes
} from "@fortawesome/fontawesome-free-solid";
import VideoContainer from './video-container';

import VideoCall from '../../common/helpers/simple-peer';
import PlaceHolderCanvasAnimation from '../../common/components/PlaceHolderCanvasAnimation';
import Terms from './Terms';
import LanguagePicker from "./LanguagePicker";

import ringtoneSound from "../assets/ringtone.mp3";
import hangupSound from "../assets/hangup.mp3";

export const STORAGE_USER_KEY = "__AIDA_CLIENT_USER__";
export const MAX_OLD_MESSAGES_SAVED = 30;

import AudioRecording from '../libs/AudioRecording';
import Operators from './Operators';
import VideoConfirmationLayout from './VideoConfirmationLayout';
import AiAssistant from './AiAssistant';

const isDev = process.env.NODE_ENV === "development";

// console.log("isDev", isDev);

// Testar Safari Mobile (iPad ou iPhone)
const isSafari = window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i);

const initialState = {
  roomId: null,
  messages: [
    {
      text: "Olá, o meu nome é AIDA e sou uma assistente virtual.",
      outbound: false,
      sentAt: new Date()
    }
  ],
  area: null,
  error: false,
  isOpen: false,
  isWaitingForBotReply: false,

  isRecognizingSpeechToText: false,
  isUsingVideoAssistant: true,
  hasVideoAssistant: true,
  videoAssistantHasMadeIntroduction: false,
  hasSpeechToText: false,

  browserInfo: {},
  isHovering: false,
  waitingForVideo: false,
  connectingVideo: false,
  videoChatConnected: false,
  userIsStreaming: false,
  askForMicPermission: false,

  wireAiMaxUserMessageLength: 300,

  language: 'pt',
  account_languages: ['pt']
};

export const AppContext = React.createContext(initialState);

let ringtone = new Audio();
let hangupRingtone = new Audio();

class AidaClient extends Component {
  constructor(props) {
    super(props);

    this.localVideoRef = React.createRef();
    this.remoteStreamRefs = [];

    const queryParams = new URLSearchParams(window.location.search);
    this.joiningRoomId = queryParams.get('joinRoomId');

    this.currentUserId = this.joiningRoomId ? -1 : this.props.currentUserId;

    // Definir toque de chamada
    ringtone.src = isDev ? this.props.apiUrl + ringtoneSound.replace('/ringtone', '/aida/cdn/aida-client/ringtone') : this.props.apiUrl + ringtoneSound.replace('ringtone', '/aida/cdn/aida-client/ringtone');
    ringtone.loop = true;

    // Definir toque de fim de chamada
    hangupRingtone.src = isDev ? this.props.apiUrl + hangupSound.replace('/hangup', '/aida/cdn/aida-client/hangup') : this.props.apiUrl + hangupSound.replace('hangup', '/aida/cdn/aida-client/hangup');
    hangupRingtone.loop = false;

    let configUrl = `${this.props.apiUrl}/aida/api/v1/config/get`;
    let account_languages = ['pt'];
    let initialMessages;
    let language;

    this.getConfigData(configUrl)
      .then(response => {
        account_languages = response.body.account.wire_ai_languages;
        if (this.getLocalStorage() && this.getLocalStorage().messages && this.getLocalStorage().messages.length > 0) {
          initialMessages = this.getLocalStorage().messages;
        } else if (account_languages.length == 1) {
          initialMessages =
            [
              {
                text: this.props.presentationMsg,
                outbound: false,
                sentAt: new Date(),
                isBot: true
              }
            ];
        } else {
          initialMessages = [];
        }
        language = account_languages.length > 1 ? null : 'pt';
        this.setState({ account_languages,  messages: initialMessages, language: language});
      })
      .catch(error => {
        console.log(error);
      });

    this.state = {
      roomId: this.getLocalStorage() ? this.getLocalStorage().roomId : null,
      error: (this.joiningRoomId != null ? "A ligar ao servidor..." : false),
      isOpen: this.joiningRoomId != null,
      isWaitingForBotReply: false,
      browserInfo: {},
      isHovering: false,
      numberOfActiveOperators: 0,
      operatorUserInfo: null,

      localStream: {},
      remoteStreams: {},
      localDisplayStream: {},
      streamType: '',
      boStreamType: '',
      peers: [{}],

      waitingForVideo: false,
      connectingVideo: false,
      videoChatConnected: false,
      userIsStreaming: false,

      mediaDevicesList: [],
      audioInputDeviceId: 0,
      videoInputDeviceId: 0,
      audioOutputDeviceId: 0,
      camState: true,
      micState: false,
      isUsingVideoAssistant: true,
      botIsActive: true,

      hasTermsAccepted: false,
      translatedTexts: null,

      actions: "",

      language: 'pt',
      acount_languages: ['pt'],


      messages: [
        {
          text: "Olá, o meu nome é AIDA e sou uma assistente virtual.",
          outbound: false,
          sentAt: new Date()
        }
      ],
    };
  }

  videoCall = new VideoCall();

  componentDidUpdate() {
    if (this.localVideoRef && this.localVideoRef.current) {
      if (this.state.localStream.constructor.name == "MediaStream") {
        if (this.localVideoRef.current.srcObject != this.state.localStream && this.localVideoRef.current.srcObject != this.state.localDisplayStream) {
          this.localVideoRef.current.srcObject = this.state.localStream;
        }
      } else {
        this.localVideoRef = React.createRef();
      }
    }

    if (this.remoteStreamRefs.length > 0) {
      Object.entries(this.state.remoteStreams).forEach((remoteStream, index) => {
        if (remoteStream[1].constructor.name == "MediaStream") {
          if (this.remoteStreamRefs[index].srcObject != remoteStream[1]) {
            this.remoteStreamRefs[index].srcObject = remoteStream[1];
          }
        }
      });
    }
  }

  async getConfigData(url) {
    try {
      const configRequest = await post(url, {
        body: JSON.stringify({
          aidaToken: this.props.aidaToken,
          tenant: this.props.tenant
        })
      });

      return await configRequest.json();
    } catch (error) {
      console.log("erro a ir buscar conta: ", error);
    }
  }

  errorLog = (msg, error) => {
    if (process.env.NODE_ENV === "development" || localStorage.debugWireChat == 'true') {
      if (data) {
        console.log("\x1b[31m%s\x1b[0m", "====================================\nDebugger => " + msg + ":\n", error);
        console.log("\x1b[31m%s\x1b[0m", "====================================");
      }
      else
        console.log("\x1b[31m%s\x1b[0m", "====================================\nDebugger => " + msg + "\n====================================");
    }
  }

  logSocketAIDebug = (msg, data) => {
    if (localStorage.debugWireChatAI == 'true') {
      if (data) {
        if (data.a_mensagem != undefined) {
          console.log("\x1b[32m%s\x1b[0m", "====================================\nAI Debugger " + msg + ":\n", data.a_mensagem);
          console.log("\x1b[32m%s\x1b[0m", "TOP 11 Documentos")
          console.table(data.b_documentos_encontrados);
          console.log("\x1b[32m%s\x1b[0m", "Documento Selecionado")
          console.table(data.c_documento_selecionado);
        }
        else {
          console.log("\x1b[32m%s\x1b[0m", "====================================\nAI Debugger " + msg + ":\n", data);
        }
        console.log("\x1b[32m%s\x1b[0m", "====================================");
      }
      else
        console.log("\x1b[32m%s\x1b[0m", "====================================\nAI Debugger " + msg + "\n====================================");
    }
  }

  debugLog = (msg, data) => {
    if (process.env.NODE_ENV === "development" || localStorage.debugWireChat == 'true') {
      if (data) {
        console.log("\x1b[35m%s\x1b[0m", "====================================\nDebugger " + msg + ":\n", data);
        console.log("\x1b[35m%s\x1b[0m", "====================================");
      }
      else
        console.log("\x1b[35m%s\x1b[0m", "====================================\nDebugger " + msg + "\n====================================");
    }
  }

  logSocketOn = (msg, data) => {
    if (process.env.NODE_ENV === "development" || localStorage.debugWireChat == 'true') {
      if (data) {
        console.log("\x1b[36m%s\x1b[0m", "====================================\nSocket <= ON <= " + msg + ":\n", data);
        console.log("\x1b[36m%s\x1b[0m", "====================================");
      }
      else
        console.log("\x1b[36m%s\x1b[0m", "====================================\nSocket <= ON <= " + msg + "\n====================================");
    }
  }

  socketEmit = (msg, data) => {
    if (process.env.NODE_ENV === "development" || localStorage.debugWireChat == 'true') {
      console.log("\x1b[33m%s\x1b[0m\n", "====================================\nSocket => EMIT => " + msg + ":\n", data);
      console.log("\x1b[33m%s\x1b[0m", "====================================");
    }

    this.socket.emit(msg, data);
  }

  getLocalStorage = () => {
    //Expira em 8h
    if (!localStorage.getItem(STORAGE_USER_KEY))
      return {};
    else {
      const storage = JSON.parse(localStorage.getItem(STORAGE_USER_KEY));
      if (storage.messages && storage.messages.length > 0) {
        const currentDate = new Date();
        const lastDate = new Date(storage.messages.slice(-1)[0].sentAt || '');
        if (Math.abs(currentDate - lastDate) > 8 * 60 * 60 * 1000) {
          localStorage.removeItem(STORAGE_USER_KEY);
          return {};
        }
      }
      return storage;
    }
  };

  writeToLocalStorage = data => {
    localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(data));
  };

  mapMessagesForStorage = messages => {
    return messages.map((m) => {
      let copy_m = Object.assign({}, m);
      delete copy_m.wireAiAssistant;
      return copy_m;
    });
  }

  toggleVideoAssistant = (showVideo = undefined) => {
    const { isUsingVideoAssistant } = this.state;
    let show = !isUsingVideoAssistant;

    if (showVideo === true || showVideo === false) {
      show = showVideo;
    }

    this.setState({
      isUsingVideoAssistant: show
    });
  };

  handleNoSpeechRecognition = () => {
    let component = this;
    if (!window.aidaAudioRecording) {
      window.aidaAudioRecording = new AudioRecording();
      window.aidaAudioRecording.setup(navigator.mediaDevices, async function (blob, audioURL) {
        console.log(blob, audioURL);
        const url = `${component.props.apiUrl}/ai/api/v1/avatar/${component.props.tenant}/speech_to_text`;
        const formData = new FormData();
        formData.append('voice_recording', blob);
        formData.append('conversationTokenId', component.state.roomId);
        const response = await fetch(url, {
          method: 'POST',
          body: formData
        });
        const response_data = await response.json();

        if (!response_data.error) {
          const event = new CustomEvent("speech_input", { detail: { text_transcript: response_data.result } });
          document.dispatchEvent(event);
        }
      })
    }
    let audioRecording = window.aidaAudioRecording;

    if (this.state.isRecognizingSpeechToText) {
      audioRecording.stop();
      this.setState({
        isRecognizingSpeechToText: false
      });
    }
    else {
      audioRecording.start();
      this.setState({
        isRecognizingSpeechToText: true
      });
    }
  }

  startSpeechToText = () => {
    this.setState({
      isRecognizingSpeechToText: true
    });

    var parent_this = this;

    var recognition = new webkitSpeechRecognition();

    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = 'pt-PT';
    recognition.start();

    recognition.onresult = function (e) {
      let text_transcript = e.results[0][0].transcript;
      console.log(text_transcript, document.getElementById('message'));
      // console.log("escreveu?", document.getElementById('message').value);
      recognition.stop();
      parent_this.setState({
        isRecognizingSpeechToText: false
      }, () => {
        const event = new CustomEvent("speech_input", { detail: { text_transcript: text_transcript } });
        document.dispatchEvent(event);
      });
    };

    recognition.onerror = function (e) {
      console.log("erro", e);
      parent_this.setState({
        isRecognizingSpeechToText: false
      });
      recognition.stop();
    };
  };

  shouldSendTermsPostRequest = () => {
    const storedTimestamp = localStorage.getItem("chat_last_terms_post_time");
    const currentTime = new Date().getTime();
    if (!storedTimestamp) {
      return true;
    }

    const lastPostTime = parseInt(storedTimestamp, 10);
    //se já passou 1 hora
    if (currentTime - lastPostTime >= 60 * 60 * 1000) {
      return true;
    }
    return false;
  };

  openChatModalClick = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      isFirstTime: false,
      shouldSendTermsPostRequest: this.shouldSendTermsPostRequest(),
    }, () => {
      if (this.state.isOpen && !this.socket) {
        this.setState({
          error: "A ligar ao servidor..."
        }, this.setupSocket);
      }
    });
  };

  handleLanguageSelect = (language) => {
    this.setState({language}, () => {
      document.dispatchEvent(new Event('wire_ai_language_chosen'));
    });
  };

  handleTranslatedTexts = (translations) => {
    const { terms } = this.props;
    const { language } = this.state;

    const replaceTermsTexts = (terms = '{}', translations) => {
      const termsJson = JSON.parse(terms.replace(/(\r\n|\n|\r)/gm,"") || '{}')

      if (!termsJson.hasOwnProperty("current")) return {} ;

      if (termsJson.current.hasOwnProperty("text") && translations.termsTexts.hasOwnProperty("text")) {
        termsJson.current.text = translations.termsTexts.text;
      }

      termsJson.current.term_items.forEach((term_item, index) => {
        const translatedTermItem = translations.termsTexts.term_items[index];

        if (translatedTermItem) {
          if (translatedTermItem.hasOwnProperty("description")) {
            term_item.description = translatedTermItem.description;
          }
          if (translatedTermItem.hasOwnProperty("title")) {
            term_item.title = translatedTermItem.title;
          }
        }
      });

      return termsJson
    }

    if (translations && language != 'pt'){
      let translatedTexts = {};
      translatedTexts["terms"] = JSON.stringify(replaceTermsTexts(terms, translations));
      translatedTexts["actions"] = translations.actions;

      const messages = this.getLocalStorage() && this.getLocalStorage().messages && this.getLocalStorage().messages.length > 0 ?
        this.getLocalStorage().messages :
        [
          {
            text: translations.introText,
            outbound: false,
            sentAt: new Date(),
            isBot: true
          }
        ]

      this.setState({ translatedTexts, messages });
    }
  };

  onMouseHover = isHovering => {
    this.setState({ isHovering });
  };

  appendMessage = msg => {
    const { error, roomId, messages, area, isUsingVideoAssistant, operatorUserInfo } = this.state;
    const { appointmentInfo } = this.props;

    if (error) {
      return;
    }

    let currentUrl = null;
    try { currentUrl = window.location.href; } catch (e) { };
    let pageTitleContext = "";
    try { pageTitleContext = document.querySelector("head title").text; } catch (e) { };

    this.socketEmit("client-message", {
      text: msg.text,
      withVideoAssistant: isUsingVideoAssistant,
      tenant: this.props.tenant,
      currentUserId: this.currentUserId,
      currentUrl,
      pageTitleContext,
      roomId: roomId,
      appointmentInfo,
      area,
      language: this.state.language
    });

    // Isto é para garantir que esta mensagem não é exibida aqui
    if (msg.text != 'requestingToJoinConversation!') {
      const newState = {
        messages: messages.concat([msg]),
        isWaitingForBotReply: !(operatorUserInfo && operatorUserInfo.photo)
      };

      this.setState(newState, () => this.writeToLocalStorage(
        {
          roomId: this.state.roomId,
          messages: this.mapMessagesForStorage(newState.messages)
        }
      ));
    } else {
      this.writeToLocalStorage({ roomId: this.state.roomId });
    }
  };

  appendFile = file => {
    this.socketEmit("client-file", {
      file,
      file_name: file.name,
      tenant: this.props.tenant,
      currentUserId: this.currentUserId,
      roomId: this.state.roomId,
    });
  };

  handleAiMessageFeedback = (msg, type) => {
    const body = {
      tenant: this.props.tenant,
      feedbackType: type,
      messageId: msg.id
    }

    this.debugLog("_giveFeedback", body);
    this.socketEmit("ai-message-feedback", body);
  }

  handleSocketConnect = (useAppointments) => {
    this.debugLog("handleSocketConnect", useAppointments);
    const { appointmentInfo } = this.props;
    let { isOpen } = this.state;

    if (appointmentInfo && useAppointments)
      isOpen = true;

    this.setState({ error: false, isOpen });

    this.socketEmit("client-login", {
      roomId: this.state.roomId,
      tenant: this.props.tenant,
      currentUserId: this.currentUserId,
      browserInfo: this.state.browserInfo,
      appointmentInfo: useAppointments ? appointmentInfo : null
    });

    if (this.joiningRoomId) {
      this.appendMessage({
        text: "requestingToJoinConversation!",
        outbound: true,
        sentAt: new Date()
      });

      if (this.state.roomId) {
        this.socketEmit("client-requested-to-join-convo", {
          tenant: this.props.tenant,
          roomId: this.state.roomId,
          joinRoomId: this.joiningRoomId
        });
      }
    }
  };

  handleClientReply = (data) => {
    const { isUsingVideoAssistant, operatorUserInfo } = this.state;
    let replyUserInfo = !data.replyByBot ? data.operatorUserInfo : { name: null, photo: null };
    replyUserInfo = replyUserInfo || {};

    if (data.closeVideo) {
      this.toggleVideoAssistant(false);
    }

    if (isUsingVideoAssistant && data.videoAssistantFileName) {
      let videoAssistant = document.getElementById('videoAssistant');
      let videoAssistantAudio = document.getElementById('videoAssistantAudio');

      if (data.videoAssistantFileName.endsWith('_mp3')) {
        videoAssistant.getElementsByTagName('source')[0].src = `${this.props.apiUrl}/ai/api/v1/avatar/${this.props.tenant}/videoAssistant?video=texto`;
        videoAssistant.loop = true;
        videoAssistant.muted = true;
        videoAssistant.load();

        videoAssistantAudio.getElementsByTagName('source')[0].src = `${this.props.apiUrl}/ai/api/v1/avatar/${this.props.tenant}/videoAssistant?audio=${data.videoAssistantFileName.replace('_', '.')}`;
        videoAssistantAudio.loop = false;
        videoAssistantAudio.load();
      }
      else {
        videoAssistant.getElementsByTagName('source')[0].src = `${this.props.apiUrl}/ai/api/v1/avatar/${this.props.tenant}/videoAssistant?video=${data.videoAssistantFileName}`;
        videoAssistant.loop = false;
        videoAssistant.muted = false;
        videoAssistant.load();
      }
    }

    let newMessages = [
      {
        outbound: data.fromSelf || false,
        text: data.reply || data.file_name,
        fileUrl: data.fileUrl,
        sentAt: new Date(),
        isBot: data.replyByBot,
        operatorUserName: replyUserInfo.name,
        operatorUserPhoto: replyUserInfo.photo,
        wireAiAssistant: data.wireAiAssistant && !data.limitReached,
        id: data.messageId,
      }
    ]

    if (data.remainingMessages == 2) {
      newMessages.push(
        {
          isWarning: true,
          text: "A próxima mensagem vai reiniciar a conversa.",
        }
      );
    }

    const newState = {
      messages: this.state.messages.concat(newMessages),
      isWaitingForBotReply: false,
      numberOfActiveOperators: data.numberOfActiveOperators,
      operatorUserInfo: data.operatorUserInfo || operatorUserInfo,
      remainingMessages: data.remainingMessages
    };

    let localStorageMessages = this.mapMessagesForStorage(newState.messages).slice(-MAX_OLD_MESSAGES_SAVED);

    this.setState(newState, () => {
      this.writeToLocalStorage({ roomId: this.state.roomId, messages: localStorageMessages })

      if (data.limitReached) {
        this.resetChat();
      }
    });
  };

  handleSocketError = (error) => {
    this.setState({
      error: "Serviço indisponível de momento. Por favor tente mais tarde."
    });
  };

  handleFinishConversation = () => {
    this.setState({
      roomId: null,
      messages: [
        {
          text: this.props.presentationMsg,
          outbound: false,
          sentAt: new Date(),
          isBot: true
        }
      ],
      isOpen: false,
      operatorUserInfo: null
    }, () => {
      this.writeToLocalStorage({});
      this.handleSocketConnect(false, '');
    })
  }

  handleUserInfo = data => {
    this.setState({
      userInfo: data,
      numberOfActiveOperators: data.numberOfActiveOperators,
      hasVideoAssistant: data.hasVideoAssistant,
      hasSpeechToText: data.hasSpeechToText,
      actions: data.actions,
      wireAiMaxUserMessageLength: data.wireAiMaxUserMessageLength,
    });
  }

  // #region VideoChat
  handleVideoChatRequest = ({ streamType }) => {
    // Tocar e vibrar
    if (!isSafari) {
      ringtone.play();
      window.navigator.vibrate && window.navigator.vibrate([2000, 300, 2000, 300, 2000, 300, 2000, 300, 2000, 300, 2000, 300, 2000, 300, 2000, 300, 2000]);
    }

    this.setState({
      waitingForVideo: true,
      boStreamType: streamType
    });
  }

  handleJoiningConvoNotAvailable = () => {
    this.debugLog("handleJoiningConvoNotAvailable");
    const newState = {
      messages: this.state.messages.concat([{ text: "Este convite não é válido.\nO atendimento já poderá ter terminado ou o link poderá ser inválido." }])
    };

    this.setState(newState, () => this.writeToLocalStorage(
      {
        roomId: this.state.roomId,
        messages: this.mapMessagesForStorage(newState.messages),
      }
    ));
  }

  switchToDeviceClick = (_e, data) => {
    this.debugLog("switchToDeviceClick", data);

    if (data.deviceType == 'audioinput') {
      this.setState({ audioInputDeviceId: data.deviceId });
    } else if (data.deviceType == 'videoinput') {
      this.setState({ videoInputDeviceId: data.deviceId });
    }

    if (data.deviceType == 'audioinput' || data.deviceType == 'videoinput') {
      let { audioInputDeviceId, videoInputDeviceId } = this.state;

      if (data.deviceType == 'audioinput') {
        audioInputDeviceId = data.deviceId;
      } else {
        videoInputDeviceId = data.deviceId;
      }

      this.setState(
        {
          audioInputDeviceId: audioInputDeviceId,
          videoInputDeviceId: videoInputDeviceId
        }, () => {
          const trackToReplace = this.state.localStream.getVideoTracks()[0];
          this.getLocalUserMedia().then(() => {
            this.debugLog("this.localVideoRef.current.srcObject 2", this.localVideoRef.current.srcObject);
            this.debugLog("this.state.localStream 2", this.state.localStream);

            this.videoCall.replaceTrack(trackToReplace, this.state.localStream.getVideoTracks()[0], this.state.localStream);
          });

          this.debugLog("this.localVideoRef.current.srcObject 1", this.localVideoRef.current.srcObject);
          this.debugLog("this.state.localStream 1", this.state.localStream);

        });
    } else { // audiooutputDeviceId
      const sinkId = data.deviceId;
      this.setState({ audioOutputDeviceId: data.deviceId });
      if (typeof this.localVideoRef.sinkId !== 'undefined') {
        this.localVideoRef.setSinkId(sinkId).then(() => {
          console.log(`Success, audio output device attached: ${sinkId}`);
        }).catch(error => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          console.error(errorMessage);
          this.setState({ audioOutputDeviceId: 0 });
        });
      } else {
        console.warn('Browser does not support output device selection.');
      }
    }
  }

  toggleAudioClick = () => {
    if (this.state.localStream.getAudioTracks().length > 0) {
      this.state.localStream.getAudioTracks().forEach(track => {
        track.enabled = !track.enabled;
      });
    }
    this.setState({
      micState: !this.state.micState
    })
  }

  toggleVideoClick = () => {
    if (this.state.localStream.getVideoTracks().length > 0) {
      this.state.localStream.getVideoTracks().forEach(track => {
        track.enabled = !track.enabled;
      });
    }
    this.setState({
      camState: !this.state.camState
    })
  }

  gotDevices = (mediaDevicesList) => {
    this.debugLog("gotDevices", mediaDevicesList);

    const { roomId, streamType } = this.state;


    this.setState({ mediaDevicesList: mediaDevicesList })

    this.getLocalUserMedia().then(async ({ error, type }) => {
      this.setState({
        waitingForVideo: false,
        connectingVideo: true
      }, () => {
        this.socketEmit("client-accepted-video-chat", {
          tenant: this.props.tenant,
          currentUserId: this.currentUserId,
          roomId: roomId,
          streamType: type
        });

        const peer = this.videoCall.init(
          this.state.localStream,
          false
        );
        this.setState({ peer });

        peer.on('signal', (signal) => {
          this.debugLog("peer.on(signal)", signal);

          if (signal.renegotiate) return;

          this.setState({
            waitingForVideo: false
          },
            () => this.socketEmit("client-video-signal", {
              tenant: this.props.tenant,
              roomId: roomId,
              desc: signal
            })
          );
        });

        peer.on('stream', (stream) => {
          this.debugLog("peer.on(stream)", stream);
          const { remoteStreams } = this.state;

          this.setState({
            waitingForVideo: false,
            connectingVideo: false,
            videoChatConnected: true,
            remoteStreams: {
              ...remoteStreams,
              [stream.id]: stream
            }
          });
        });

        peer.on('error', (err) => {
          this.debugLog("peer.on(error)", err);

          if (err.toString().includes('reason=Close called')) {
            this.handleVideoChatEndSignal();
          } else {
            this.errorLog("Not treated peer error", err);
          }
        });
      });
    });
  };

  problemGettingDevices = (error) => {
    this.errorLog('problemGettingDevices', error);
  };

  getDisplayMediaForScreenSharing = () => {
    const { userIsStreaming } = this.state;

    return new Promise((resolve, reject) => {

      const constraints = {
        video: {
          cursor: 'always'
        },
        audio: {
          echoCancellation: true,
          noiseSuppression: true
        }
      };

      if (navigator.mediaDevices) {
        navigator.mediaDevices.getDisplayMedia(constraints).then(
          stream => {
            // Substituimos o video local pelo que estamos a partilhar
            this.state.localDisplayStream = this.localVideoRef.current.srcObject = stream;
            // Substituimos a track que estamos a enviar pelo screen share.
            this.videoCall.replaceTrack(this.state.localStream.getVideoTracks()[0], this.state.localDisplayStream.getVideoTracks()[0], this.state.localStream);

            // Quando alguém clica no botão de stop sharing
            stream.getVideoTracks()[0].onended = () => {
              console.log("Desligou?");
              this.localVideoRef.current.srcObject = this.state.localStream;

              this.videoCall.replaceTrack(this.state.localStream.getVideoTracks()[0], this.localVideoRef.current.srcObject.getVideoTracks()[0], this.state.localStream);
              this.setState({ userIsStreaming: false }); // Repor o state original
            };

            this.setState({ userIsStreaming: true });

            resolve({ error: false, type: 'screen_share' });
          }).catch(
            (error) => {
              // Falhou a alteração do stream para screen share... o que fazer?
              this.errorLog("Falhou ao passar o stream para screen share", error);
              this.setState({ userIsStreaming: false }); // Repor o state original
            }
          );
      } else {
        this.errorLog("mediaDevices not accessible!")
        reject({ error: true })
      }
    });
  }

  getLocalUserMedia = () => {
    this.debugLog("getLocalUserMedia");
    let { mediaDevicesList, audioInputDeviceId, videoInputDeviceId, audioOutputDeviceId, localStream } = this.state;

    return new Promise((resolve, reject) => {
      if (navigator.mediaDevices) {
        if (mediaDevicesList.length > 0) {
          if (videoInputDeviceId == 0) {
            if (isDev) {
              if (this.joiningRoomId) {
                if (mediaDevicesList.find(elem => elem.kind == 'videoinput' && elem.label.startsWith("Integrated Webcam"))) {
                  videoInputDeviceId = mediaDevicesList.find(elem => elem.kind == 'videoinput' && elem.label.startsWith("Integrated Webcam")).deviceId;
                } else {
                  videoInputDeviceId = mediaDevicesList.find(elem => elem.kind == 'videoinput').deviceId;
                }
              } else {
                if (mediaDevicesList.find(elem => elem.kind == 'videoinput' && elem.label == "OBS Virtual Camera")) {
                  videoInputDeviceId = mediaDevicesList.find(elem => elem.kind == 'videoinput' && elem.label == "OBS Virtual Camera").deviceId;
                } else {
                  videoInputDeviceId = mediaDevicesList.find(elem => elem.kind == 'videoinput').deviceId;
                }
              }
            } else {
              videoInputDeviceId = mediaDevicesList.find(elem => elem.kind == 'videoinput').deviceId;
            }
          }

          if (audioInputDeviceId == 0) {
            audioInputDeviceId = mediaDevicesList.find(elem => elem.kind == 'audioinput').deviceId;
          }

          if (mediaDevicesList.find(elem => elem.kind == 'audiooutput') && audioOutputDeviceId == 0) {
            audioOutputDeviceId = mediaDevicesList.find(elem => elem.kind == 'audiooutput').deviceId;
          }

          this.setState({
            audioInputDeviceId: audioInputDeviceId,
            videoInputDeviceId: videoInputDeviceId,
            audioOutputDeviceId: audioOutputDeviceId,
          });
        }

        let constraints = {
          audio: {
            deviceId: audioInputDeviceId ? { exact: audioInputDeviceId } : undefined
          },
          video: {
            width: { min: 160, ideal: 640, max: 1280 },
            height: { min: 120, ideal: 360, max: 720 },
            deviceId: videoInputDeviceId ? { exact: videoInputDeviceId } : undefined
          }
        };

        // Isto não funciona em firefox
        // navigator.permissions.query({ name: 'microphone' }).then((permissionStatus) => {
        //   if(permissionStatus.state != "granted"){
        //     this.setState({ askForMicPermission: true });
        //   }
        //   permissionStatus.onchange = ()=>{
        //     if(permissionStatus.state == "granted"){
        //       this.setState({ askForMicPermission: false });
        //     }
        //   }
        // });

        if (localStream.constructor.name == "MediaStream") {
          localStream.getTracks().forEach(track => {
            track.stop();
          });
        }

        navigator.mediaDevices.getUserMedia(constraints).then(
          stream => {
            this.setState({ localStream: stream, streamType: 'video', askForMicPermission: false });

            if (this.localVideoRef.current) {
              this.localVideoRef.current.srcObject = stream;
            }

            resolve({ error: false, type: 'video' });
          }).catch((err) => {
            console.log("FALLBACK", err);
            delete constraints["video"]

            navigator.mediaDevices.getUserMedia(constraints).then(
              stream => {
                const canvas = document.getElementById('placeHolderCanvasAnimation');
                let dummyCanvasStream = canvas.captureStream();
                Object.assign(dummyCanvasStream.getVideoTracks()[0], { enabled: true });
                stream.addTrack(Object.assign(dummyCanvasStream.getVideoTracks()[0], { enabled: true }));

                this.setState({ localStream: stream, streamType: 'video', askForMicPermission: false });

                if (this.localVideoRef.current) {
                  this.localVideoRef.current.srcObject = stream;
                }

                resolve({ error: false, type: 'video' });
              }).catch(
                (error) => {
                  this.errorLog("Segunda tentativa de getUserMedia deu erro", error);
                  reject({ error: true })
                }
              );
          }
          );
      } else {
        this.errorLog("Sem acesso a mediaDevices");
        reject({ error: true })
      }
    });
  }

  acceptVideoChatClick = async () => {
    // Parar de tocar
    if (!isSafari) {
      ringtone.pause();
      window.navigator.vibrate && window.navigator.vibrate(0);
    }

    if (navigator.mediaDevices) {
      this.getLocalUserMedia().then(async ({ error, type }) => {
        navigator.mediaDevices.enumerateDevices().then(this.gotDevices).catch(this.problemGettingDevices);
      });
    }
  }

  shareScreenClick = () => {
    this.debugLog("shareScreenClick");
    const { roomId, streamType } = this.state;

    // Aqui dispara o ecrã de escolha de ecrâ a partilhar e substitui o stream local.
    this.getDisplayMediaForScreenSharing();
  }

  turnOffVideoChatClick = () => {
    this.debugLog("turnOffVideoChatClick");
    // Parar de tocar
    if (!isSafari) {
      ringtone.pause();
      window.navigator.vibrate && window.navigator.vibrate(0);
    }

    // Tocar
    if (!isSafari) {
      hangupRingtone.play();
    }

    this.socketEmit("client-turned-off-call", {
      tenant: this.props.tenant,
      roomId: this.state.roomId,
    });

    this.remoteStreamRefs = [];

    // duplicação de métodos? nope: o peer não existe ainda
    this.setState({
      askForMicPermission: false,
      waitingForVideo: false,
      connectingVideo: false,
      videoChatConnected: false,
      remoteStreams: {}
    }, () => {
      this.handleCallShutDown();
    });
  };

  rejectVideoChatClick = () => {
    // Parar de tocar
    if (!isSafari) {
      ringtone.pause();
      window.navigator.vibrate && window.navigator.vibrate(0);
    }

    // Tocar
    if (!isSafari) {
      hangupRingtone.play();
    }

    this.socketEmit("client-declined-call", {
      tenant: this.props.tenant,
      roomId: this.state.roomId,
    });

    this.remoteStreamRefs = [];

    // duplicação de métodos? nope: o peer não existe ainda
    this.setState({
      askForMicPermission: false,
      waitingForVideo: false,
      connectingVideo: false,
      videoChatConnected: false,
      remoteStreams: {}
    }, () => {
      this.handleCallShutDown();
    });
  }

  handleVideoChatEndSignal = () => {
    this.remoteStreamRefs = [];

    this.setState({
      askForMicPermission: false,
      waitingForVideo: false,
      connectingVideo: false,
      videoChatConnected: false,
      remoteStreams: {}
    }, () => {
      this.handleCallShutDown();
    });
    // Tocar
    if (!isSafari) {
      if (!ringtone.paused)
        ringtone.pause();
      hangupRingtone.play();
    }
  }

  handleCallShutDown = () => {
    if (this.state.localStream && this.state.localStream.constructor.name == "MediaStream") {
      this.state.localStream.getTracks().forEach(track => track.stop()); // parar as tracks todas
    }

    if (this.state.localDisplayStream && this.state.localDisplayStream.constructor.name == "MediaStream") {
      this.state.localDisplayStream.getTracks().forEach(track => track.stop()); // parar as tracks todas
    }

    if (this.state.peer && this.state.peer.destroy) {
      this.state.peer.destroy();
    }

    if (this.localVideoRef && this.localVideoRef.current) {
      this.localVideoRef.current.srcObject = null;
    }

    this.setState({ localStream: {}, peer: {} });
  };

  //#endregion VideoChat

  resetChat = () => {
    this.debugLog("ResetChat");
    this.socketEmit("client-reset", {
      tenant: this.props.tenant,
      roomId: this.state.roomId
    });
    // Colocar o chat num estado de reseting
  }

  setupSocket = () => {
    if (this.socket) {
      return;
    }

    this.socket = io(`${this.props.apiUrl}/client`, { path: "" });

    this.socket.on("connect", () => {
      this.logSocketOn("connect");
      this.handleSocketConnect(true);
    });

    this.socket.on("client-reset-successful", (data) => {
      this.debugLog("Client reset  successful", data);
      // Faz alguma coisa para dar refresh ao estado
      // Colocar o chat fora do estado de reseting
      let { roomId, numberOfActiveOperators } = data;

      // Adicionar uma nova mensagem de separação

      let newState = {
        roomId: roomId,
        messages: [ ...this.state.messages, {
          text: "Sessão terminada",
          isSeparator: true
        }]
      };

      this.setState(newState, () => {
        this.writeToLocalStorage({
          roomId: this.state.roomId,
          messages: this.mapMessagesForStorage(newState.messages)
        });

        document.dispatchEvent(new Event('wire_ai_reset_conversation'));
      });

    });

    this.socket.on("client-token", (data) => {
      this.logSocketOn("client-token", data);
      // this.setState( { error: null }, this.storeSocketToken(data));

      this.setState(
        {
          error: null,
          roomId: data.roomId,
          messages: this.state.messages.slice(-MAX_OLD_MESSAGES_SAVED)
        }, () => {
          this.writeToLocalStorage({ roomId: data.roomId, messages: this.mapMessagesForStorage(this.state.messages).slice(-MAX_OLD_MESSAGES_SAVED) });

          if (this.joiningRoomId) {
            this.socketEmit("client-requested-to-join-convo", {
              tenant: this.props.tenant,
              roomId: this.state.roomId,
              joinRoomId: this.joiningRoomId
            });
          }
        }
      );
    });

    this.socket.on("client-reply", (data) => {
      this.logSocketOn("client-reply", data);
      this.handleClientReply(data);
    });

    this.socket.on("connect_error", (error) => {
      this.logSocketOn("connect_error");
      this.handleSocketError(error);
    });

    this.socket.on("client-user-info", (data) => {
      this.logSocketOn("client-user-info");
      this.handleUserInfo(data);
    });

    this.socket.on("client-new-responsable-for-conversation", (data) => {
      this.logSocketOn("client-new-responsable-for-conversation", data);
      this.setState({ operatorUserInfo: data.operatorUserInfo, botIsActive:  data.botActive });
    });

    this.socket.on("client-set-available-operators", ({ numberOfActiveOperators }) => {
      this.logSocketOn("client-set-available-operators", { numberOfActiveOperators });
      this.setState({ numberOfActiveOperators });
    });

    this.socket.on("backoffice-asked-to-start-video-chat", (data) => {
      this.logSocketOn("backoffice-asked-to-start-video-chat", data);
      this.handleVideoChatRequest(data);
    });

    this.socket.on("backoffice-said-invitation-no-longer-available", () => {
      this.logSocketOn("backoffice-said-invitation-no-longer-available");
      this.handleJoiningConvoNotAvailable();
    });

    this.socket.on("backoffice-video-desc", (data) => {
      this.logSocketOn("backoffice-video-desc", data);
      this.videoCall.connect(data);
    });

    this.socket.on("backoffice-ended-video-chat", () => {
      this.logSocketOn("backoffice-ended-video-chat");
      this.handleVideoChatEndSignal();
    });

    this.socket.on("client-close-conversation", () => {
      this.logSocketOn("client-close-conversation");
      this.handleFinishConversation();
    });

    this.socket.on("client-remove-stream", (data) => {
      this.logSocketOn("client-remove-stream", data);
      const { remoteStreams } = this.state;
      delete remoteStreams[data.streamId];
      this.setState({
        remoteStreams
      });
    });

    this.socket.on("client-set-area", ({ area }) => {
      this.logSocketOn("client-set-area", { area });
      this.setState({ area })
    });

    this.socket.on("ai-debugger-info", (data) => {
      this.logSocketAIDebug("ai-debugger-info", data);
    });
  };

  componentDidMount() {
    this.debugLog("componentDidMount");

    this.debugLog("state", this.state);
    this.debugLog("this.socket", this.socket);

    const shouldSendTermsPostRequest = this.shouldSendTermsPostRequest();
    this.setState({ shouldSendTermsPostRequest });

    if (this.state.isOpen && (!this.socket || this.socket == null || this.socket == undefined)) {
      this.debugLog("vai chamar");
      this.setupSocket();
      this.debugLog("chamou");
    }

    // * Esperar e ocultar a mensagem de chat pré-definida da primeira vez
    if (!localStorage.chat_alerted) {
      this.setState({
        isHovering: true
      });
      setTimeout(() => {
        this.setState({
          isHovering: false
        });
        localStorage.setItem('chat_alerted', true);
      }, 7500);
    }
    // * Primeiro fazemos o detect do que queremos do utilizador
    const currentPage = window.location.href;
    const userAgent = detect.parse(navigator.userAgent);
    const scrollPosition = document.documentElement.scrollTop;

    setTimeout(() => {
      this.setState({
        isHovering: false
      });
      localStorage.setItem('chat_alerted', true);
    }, 0);

    this.setState(
      {
        browserInfo: {
          userAgent,
          currentPage,
          scrollPosition
        }
      },
      () => {
        if (this.props.appointmentInfo)
          this.openChatModalClick();
      }
    );

    const { openOnFirstTime = true } = this.props;

    if(!localStorage.getItem('aida_first_time') && openOnFirstTime) {
      const { terms } = this.props;
      const hasTerms = terms && terms != "{}";
      const onPageLoad = () => {
        localStorage.setItem('aida_first_time', new Date());
        setTimeout(() => { this.openChatModalClick(); }, 1000);
      };

      this.setState({
        isFirstTime: true
      });

      if (document.readyState === 'complete') {
        onPageLoad();
      } else {
        window.addEventListener('load', onPageLoad, false);
        // Remove the event listener when component unmounts
        return () => window.removeEventListener('load', onPageLoad);
      }
      // this.openChatModalClick();
    }
  }

  render() {
    const { botName, imageUrl, slabHello, slabIntro, terms = '{}', hasFileUpload } = this.props;
    const {
      isOpen,
      isWaitingForBotReply,
      isHovering,
      isRecognizingSpeechToText,
      isUsingVideoAssistant,
      videoAssistantHasMadeIntroduction,
      hasVideoAssistant,
      hasSpeechToText,
      operatorUserInfo,
      waitingForVideo,
      connectingVideo,
      videoChatConnected,
      mediaDevicesList,
      audioInputDeviceId,
      audioOutputDeviceId,
      videoInputDeviceId,
      camState,
      micState,
      streamType,
      boStreamType,
      userIsStreaming,
      roomId,
      numberOfActiveOperators,
      remoteStreams,
      shouldSendTermsPostRequest,
      language,
      translatedTexts
    } = this.state;

    // TODO: Temporario para simular ativar o chatbot e não
    let hasFeatureWireAi = true;

    const hasTerms = terms && terms != "{}";

    if (!hasTerms && this.state.hasTermsAccepted == false) {
      this.setState({hasTermsAccepted: true});
    }

    let pose = "closed";

    if (isOpen) {
      pose = "open";
    } else if (this.state.isFirstTime) {
      pose = "firstTime";
    }
    else if (isHovering) {
      pose = "hovered";
    }

    let contextTrigger = null;

    const toggleMenuClick = e => {
      if (contextTrigger) {
        contextTrigger.handleContextClick(e);
      }
    };

    // const transitionStyles = {
    //   entering: { opacity: 1 },
    //   entered:  { opacity: 1 },
    //   exiting:  { opacity: 0 },
    //   exited:  { opacity: 0 },
    // };

    return (
      <ThemeProvider theme={this.props.theme}>
        <AppContext.Provider
          value={{
            ...this.state,
            imageUrl: imageUrl,
            botName: botName,
            openChatModalClick: this.openChatModalClick,
            startSpeechToText: this.startSpeechToText,
            handleNoSpeechRecognition: this.handleNoSpeechRecognition,
            toggleVideoAssistant: this.toggleVideoAssistant,
            onMouseHover: this.onMouseHover,
            appendMessage: this.appendMessage,
            appendFile: this.appendFile,
            handleAiMessageFeedback: this.handleAiMessageFeedback,
            changeLanguage: (language) => this.setState({language}),
            apiUrl: this.props.apiUrl,
            isOpen: this.state.isOpen,
            hasVideoAssistant: this.state.hasVideoAssistant,
            hasFileUpload: hasFileUpload,
            waitingForBotReply: isWaitingForBotReply
          }}
        >
          <Container
            className={
              (waitingForVideo || connectingVideo || videoChatConnected)
                && streamType == 'video'
                ? 'has-video'
                : isOpen ? 'container-open' : 'container-closed'
            }
          >
            <Slab pose={pose}>
              {isOpen && !language && this.state.account_languages.length > 1 && (
                <LanguagePicker
                  onSelectLanguage={this.handleLanguageSelect}
                  termsCurrent={JSON.parse(terms.replace(/(\r\n|\n|\r)/gm,"") || '{}').current}
                  presentationMsg={this.props.presentationMsg}
                  tenant={this.props.tenant}
                  apiUrl={this.props.apiUrl}
                  onTranslatedTexts={this.handleTranslatedTexts}
                  accountLanguages={this.state.account_languages}
                />
              )}
              {/* pedir para aceitar temos antes de entrar na chamada */}
              {isOpen && hasTerms && roomId && language && (
                <Terms
                  apiUrl={this.props.apiUrl}
                  tenant={this.props.tenant}
                  numberOfActiveOperators={numberOfActiveOperators}
                  imageUrl={imageUrl}
                  botName={botName}
                  hasFileUpload={hasFileUpload}
                  roomId={roomId}
                  language={language}
                  shouldSendTermsPostRequest={shouldSendTermsPostRequest}
                  terms={language != 'pt' && translatedTexts ? translatedTexts.terms : terms }
                  onAcceptTerms={(accepted, triggerEvent = true) => {
                    this.setState({hasTermsAccepted: accepted});
                    if(accepted && triggerEvent) {
                      document.dispatchEvent(new Event('wire_ai_terms_accepted'));
                    }
                  }}
                />
              )}

              <span className={isOpen ? "close-btn" : "close-btn hide"} onClick={this.openChatModalClick}>
                <FontAwesomeIcon icon={faTimes} color="#546269" />
              </span>
              {isOpen && !((waitingForVideo || connectingVideo || videoChatConnected)) &&
                <ChatHeader className={operatorUserInfo && operatorUserInfo.name ? "auto-margin" : "no-margin"}>
                  <div style={{ display: 'none' }}>
                    <PlaceHolderCanvasAnimation text={this.joiningRoomId ? "Convidado" : "Cliente"}></PlaceHolderCanvasAnimation>
                  </div>
                  <div className="header-name">
                    {operatorUserInfo && operatorUserInfo.name ?
                      <span>{operatorUserInfo.name}</span>
                      : <span>{botName}</span>
                    }
                  </div>
                </ChatHeader>
              }

              {isOpen && (!hasFeatureWireAi) && (
                <React.Fragment>
                  {!(operatorUserInfo) && (<Operators
                    numberOfOperators={numberOfActiveOperators}
                    onClick={() => this.appendMessage({
                      text: "operador",
                      outbound: true,
                      sentAt: new Date()
                    })}
                    linear={true}
                  />)}
                  <VideoConfirmationLayout
                    operatorUserInfo={operatorUserInfo}
                    incoming={this.joiningRoomId === undefined}
                    accept={this.acceptVideoChatClick}
                    reject={this.rejectVideoChatClick}
                    waitingForVideo={this.state.waitingForVideo}
                    joiningRoomId={this.joiningRoomId}
                    videoChatConnected={videoChatConnected}
                    connectingVideo={connectingVideo}
                    />

                  {/* Control C control V debaixo, se mudar-se algo aqui mudar abauxi */}
                  <VideoChatContainer
                    className={`
                      ${videoChatConnected ? 'connected' : ''}
                      ${userIsStreaming ? 'userIsStreaming' : ''}
                    `}
                  >
                    {(waitingForVideo || connectingVideo || videoChatConnected) && streamType == 'video' &&
                      <React.Fragment>
                        <video
                          style={{ maxHeight: '90px' }}
                          autoPlay
                          id='localVideo'
                          height='100%'
                          width='100%'
                          muted
                          playsInline
                          ref={this.localVideoRef}
                        />
                        {userIsStreaming && <span className="infoLabel">O meu ecrã</span>}
                      </React.Fragment>
                    }

                    {videoChatConnected && boStreamType == 'video' &&
                      <div className="remote-videos">
                        {Object.entries(remoteStreams).map((stream, index) => {
                          return (
                            <video
                              style={{ visibility: 'visible', maxHeight: '500px' }}
                              autoPlay
                              height='100%'
                              width='100%'
                              controls
                              muted={isSafari}
                              playsInline
                              id={`remoteVideo_${stream[0]}`}
                              key={`remoteVideo_${stream[0]}`}
                              ref={elem => this.remoteStreamRefs[index] = elem}
                            />
                          );
                        })}
                      </div>
                    }

                    {/* Acções durante a chamada */}
                    {(waitingForVideo || connectingVideo || videoChatConnected) &&
                      <React.Fragment>
                        {/* Botões de desligar chamada & partilhar o ecrã */}
                        <CallButtonContainer>
                          <UserShare
                            onClick={this.shareScreenClick}
                            title={'Partilhar ecrã'}
                            className={userIsStreaming && 'userIsStreaming'}
                          >
                            <FontAwesomeIcon icon={faDesktop} /> {userIsStreaming ? 'Partilha ativada' : 'Partilhar ecrã'}
                            <BetaTag>BETA</BetaTag>
                          </UserShare>
                          <UserHangUp
                            onClick={this.turnOffVideoChatClick}
                            title={'Desligar chamada'}
                          >
                            <FontAwesomeIcon icon={faPhoneSlash} /> Desligar
                          </UserHangUp>
                        </CallButtonContainer>

                        {/* Controlos para desligar câmara e/ou microfone */}
                        <LocalVideoControls className={videoChatConnected && 'connected'}>
                          {streamType == 'video' && (
                            <button
                              style={{ borderRadius: '4px 0 0 4px' }}
                              onClick={this.toggleVideoClick}
                              title={camState ? 'Desligar Video' : 'Ligar Video'}>
                              {
                                camState ? (
                                  <FontAwesomeIcon icon={faVideo} />
                                ) : (
                                  <FontAwesomeIcon icon={faVideoSlash} />
                                )
                              }
                            </button>
                          )}

                          {/* Este statement fabulosamente complicado serve para dar o estilo certo aos botões 😎 */}
                          <button
                            style={
                              streamType == 'audio'
                                ? waitingForVideo
                                  ? { borderRadius: '4px 0 0 4px' }
                                  : { borderRadius: '4px' }
                                : waitingForVideo
                                  ? { borderRadius: '0' }
                                  : { borderRadius: '0 4px 4px 0' }
                            }
                            onClick={this.toggleAudioClick}
                            title={micState ? 'Desligar Microfone' : 'Ligar Microfone'}>
                            {
                              micState ? (
                                <FontAwesomeIcon icon={faMicrophone} />
                              ) : (
                                <FontAwesomeIcon icon={faMicrophoneSlash} />
                              )
                            }
                          </button>
                        </LocalVideoControls>

                        {/* Escolha de dispositivos de input, output e vídeo */}
                        <LocalVideoConfig>
                          <ContextMenuTrigger id="local-video-sources" ref={c => contextTrigger = c}>
                            <button
                              onClick={toggleMenuClick}
                              title='Configurações'
                            >
                              <FontAwesomeIcon icon={faCog} />
                              <span>Dispositivos</span>
                            </button>
                          </ContextMenuTrigger>
                          <ContextMenu id="local-video-sources">
                            {mediaDevicesList.map((item, i) => {
                              return [
                                (i > 0 && mediaDevicesList[i - 1].kind != mediaDevicesList[i].kind &&
                                  <React.Fragment>
                                    <MenuItem divider />
                                  </React.Fragment>
                                ),
                                <MenuItem
                                  key={item.groupId}
                                  data={{
                                    deviceType: item.kind,
                                    deviceId: item.deviceId
                                  }}
                                  onClick={this.switchToDeviceClick}
                                >
                                  {item.kind == 'audioinput' && (item.deviceId == audioInputDeviceId ?
                                    <React.Fragment>
                                      <FontAwesomeIcon style={{ opacity: 0.8 }} icon={faMicrophone} />
                                      <span><strong>{item.label}</strong></span>
                                    </React.Fragment>
                                    : <span>{item.label}</span>
                                  )}
                                  {item.kind == 'videoinput' && (item.deviceId == videoInputDeviceId ?
                                    <React.Fragment>
                                      <FontAwesomeIcon style={{ opacity: 0.8 }} icon={faVideo} />
                                      <span><strong>{item.label}</strong></span>
                                    </React.Fragment>
                                    : <span>{item.label}</span>
                                  )}
                                  {item.kind == 'audiooutput' && (item.deviceId == audioOutputDeviceId ?
                                    <React.Fragment>
                                      <FontAwesomeIcon style={{ opacity: 0.8 }} icon={faVolumeUp} />
                                      <span><strong>{item.label}</strong></span>
                                    </React.Fragment>
                                    : <span>{item.label}</span>
                                  )}
                                </MenuItem>
                              ];
                            })}
                          </ContextMenu>
                        </LocalVideoConfig>
                      </React.Fragment>
                    }
                  </VideoChatContainer>
                  {/* Control C control V debaixo, se mudar-se algo aqui mudar abauxi */}
                </React.Fragment>
              )}

              {isOpen && (
                <SplitPanel>
                  {hasFeatureWireAi && (
                    <VideoContainer
                      toggleAssistant={this.toggleVideoAssistant}
                      isAssistantVideoActive={!!isUsingVideoAssistant}
                      hasVideoAssistant={hasVideoAssistant}
                      botIsActive={this.state.botIsActive}
                      actions={language != 'pt' && translatedTexts ? translatedTexts.actions : this.state.actions }
                      appendMessage={this.appendMessage}
                      resetChat={this.resetChat}
                      hasTermsAccepted={this.state.hasTermsAccepted}
                      language={language}
                    >

                      {/* Está aberto e não tem informação do operador */}
                      <ActiveUsers>
                        {/* Ou é um ai assistant, ou é um operador */}
                        {(!!isUsingVideoAssistant && !!this.state.botIsActive) ? (
                          <AiAssistant
                            setParentState={(newState) => {
                              this.setState(newState);
                            }}
                            videoAssistantHasMadeIntroduction={videoAssistantHasMadeIntroduction}
                            apiUrl={this.props.apiUrl}
                            tenant={this.props.tenant}
                            hasTerms={hasTerms}
                            language={language}
                            accountLanguages={this.state.account_languages}
                          />
                        ) : (
                          <Operators
                            numberOfOperators={numberOfActiveOperators}
                            onClick={() => this.appendMessage({
                              text: "operador",
                              outbound: true,
                              sentAt: new Date()
                            })}
                            isTalkingWithOperator={!!operatorUserInfo}
                            botIsActive={this.state.botIsActive}
                          />
                        )}
                      </ActiveUsers>

                      <VideoConfirmationLayout
                        operatorUserInfo={operatorUserInfo}
                        incoming={this.joiningRoomId === undefined}
                        accept={this.acceptVideoChatClick}
                        reject={this.rejectVideoChatClick}
                        waitingForVideo={this.state.waitingForVideo}
                        joiningRoomId={this.joiningRoomId}
                        videoChatConnected={videoChatConnected}
                        connectingVideo={connectingVideo}
                        />

                      {/* Control C control V acima, se mudar-se algo aqui mudar acima */}
                      <VideoChatContainer
                        className={`
                          ${videoChatConnected ? 'connected' : ''}
                          ${userIsStreaming ? 'userIsStreaming' : ''}
                        `}
                      >
                        {/* {(waitingForVideo || connectingVideo || videoChatConnected) && streamType == 'video' &&
                          <React.Fragment>
                            <video
                              style={{ maxHeight: '90px' }}
                              autoPlay
                              id='localVideo'
                              height='100%'
                              width='100%'
                              muted
                              playsInline
                              ref={this.localVideoRef}
                            />
                            {userIsStreaming && <span className="infoLabel">O meu ecrã</span>}
                          </React.Fragment>
                        } */}

                        {videoChatConnected && boStreamType == 'video' &&
                          <div className="remote-videos">
                            {(waitingForVideo || connectingVideo || videoChatConnected) && streamType == 'video' &&
                              <React.Fragment>
                                <video
                                  style={{ visibility: 'visible' }}
                                  autoPlay
                                  id='localVideo'
                                  muted
                                  playsInline
                                  ref={this.localVideoRef}
                                >
                                  {userIsStreaming && <span className="infoLabel">O meu ecrã</span>}
                                </video>
                              </React.Fragment>
                            }
                            {Object.entries(remoteStreams).map((stream, index) => {
                              return (
                                <video
                                  style={{ visibility: 'visible' }}
                                  autoPlay
                                  controls
                                  muted={isSafari}
                                  playsInline
                                  id={`remoteVideo_${stream[0]}`}
                                  key={`remoteVideo_${stream[0]}`}
                                  ref={elem => this.remoteStreamRefs[index] = elem}
                                />
                              );
                            })}
                          </div>
                        }

                        {/* Acções durante a chamada */}
                        {(waitingForVideo || connectingVideo || videoChatConnected) &&
                          <React.Fragment>
                            {/* Botões de desligar chamada & partilhar o ecrã */}
                            <CallButtonContainer>
                              <UserShare
                                onClick={this.shareScreenClick}
                                title={'Partilhar ecrã'}
                                className={userIsStreaming && 'userIsStreaming'}
                              >
                                <FontAwesomeIcon icon={faDesktop} /> {userIsStreaming ? 'Partilha ativada' : 'Partilhar ecrã'}
                                <BetaTag>BETA</BetaTag>
                              </UserShare>
                              <UserHangUp
                                onClick={this.turnOffVideoChatClick}
                                title={'Desligar chamada'}
                              >
                                <FontAwesomeIcon icon={faPhoneSlash} /> Desligar
                              </UserHangUp>
                            </CallButtonContainer>

                            {/* Controlos para desligar câmara e/ou microfone */}
                            <LocalVideoControls className={videoChatConnected && 'connected'}>
                              {streamType == 'video' && (
                                <button
                                  style={{ borderRadius: '4px 0 0 4px' }}
                                  onClick={this.toggleVideoClick}
                                  title={camState ? 'Desligar Video' : 'Ligar Video'}>
                                  {
                                    camState ? (
                                      <FontAwesomeIcon icon={faVideo} />
                                    ) : (
                                      <FontAwesomeIcon icon={faVideoSlash} />
                                    )
                                  }
                                </button>
                              )}

                              {/* Este statement fabulosamente complicado serve para dar o estilo certo aos botões 😎 */}
                              <button
                                style={
                                  streamType == 'audio'
                                    ? waitingForVideo
                                      ? { borderRadius: '4px 0 0 4px' }
                                      : { borderRadius: '4px' }
                                    : waitingForVideo
                                      ? { borderRadius: '0' }
                                      : { borderRadius: '0 4px 4px 0' }
                                }
                                onClick={this.toggleAudioClick}
                                title={micState ? 'Desligar Microfone' : 'Ligar Microfone'}>
                                {
                                  micState ? (
                                    <FontAwesomeIcon icon={faMicrophone} />
                                  ) : (
                                    <FontAwesomeIcon icon={faMicrophoneSlash} />
                                  )
                                }
                              </button>
                            </LocalVideoControls>

                            {/* Escolha de dispositivos de input, output e vídeo */}
                            <LocalVideoConfig>
                              <ContextMenuTrigger id="local-video-sources" ref={c => contextTrigger = c}>
                                <button
                                  onClick={toggleMenuClick}
                                  title='Configurações'
                                >
                                  <FontAwesomeIcon icon={faCog} />
                                  <span>Dispositivos</span>
                                </button>
                              </ContextMenuTrigger>
                              <ContextMenu id="local-video-sources">
                                {mediaDevicesList.map((item, i) => {
                                  return [
                                    (i > 0 && mediaDevicesList[i - 1].kind != mediaDevicesList[i].kind &&
                                      <React.Fragment>
                                        <MenuItem divider />
                                      </React.Fragment>
                                    ),
                                    <MenuItem
                                      key={item.groupId}
                                      data={{
                                        deviceType: item.kind,
                                        deviceId: item.deviceId
                                      }}
                                      onClick={this.switchToDeviceClick}
                                    >
                                      {item.kind == 'audioinput' && (item.deviceId == audioInputDeviceId ?
                                        <React.Fragment>
                                          <FontAwesomeIcon style={{ opacity: 0.8 }} icon={faMicrophone} />
                                          <span><strong>{item.label}</strong></span>
                                        </React.Fragment>
                                        : <span>{item.label}</span>
                                      )}
                                      {item.kind == 'videoinput' && (item.deviceId == videoInputDeviceId ?
                                        <React.Fragment>
                                          <FontAwesomeIcon style={{ opacity: 0.8 }} icon={faVideo} />
                                          <span><strong>{item.label}</strong></span>
                                        </React.Fragment>
                                        : <span>{item.label}</span>
                                      )}
                                      {item.kind == 'audiooutput' && (item.deviceId == audioOutputDeviceId ?
                                        <React.Fragment>
                                          <FontAwesomeIcon style={{ opacity: 0.8 }} icon={faVolumeUp} />
                                          <span><strong>{item.label}</strong></span>
                                        </React.Fragment>
                                        : <span>{item.label}</span>
                                      )}
                                    </MenuItem>
                                  ];
                                })}
                              </ContextMenu>
                            </LocalVideoConfig>
                          </React.Fragment>
                        }
                      </VideoChatContainer>
                      {/* Control C control V acima, se mudar-se algo aqui mudar acima */}
                    </VideoContainer>
                  )}
                  <Messages />
                </SplitPanel>
              )}

              {/* Caixa de texto para enviar ao operador */}
              {isOpen && this.state.hasTermsAccepted && language && !((waitingForVideo || connectingVideo || videoChatConnected)) && <Form
                hasSpeechToText={hasSpeechToText}
                isRecognizingSpeechToText={this.state.isRecognizingSpeechToText}
                wireAiMaxUserMessageLength={this.state.wireAiMaxUserMessageLength}
              />}

              {/* Badge de aceder ao chat */}
              { ['hovered', 'firstTime'].includes(pose) && !isOpen && (
                <React.Fragment>
                  <SlabTitle>{slabHello}</SlabTitle>
                  <SlabSubTitle>{slabIntro}</SlabSubTitle>
                </React.Fragment>
              )}

            </Slab>
            <Avatar
              pose={pose}
              alt="Chatbot"
              src={
                !!imageUrl
                  ? imageUrl
                  : "https://image.flaticon.com/icons/svg/682/682037.svg"
              }
            />
          </Container>


          {this.state.askForMicPermission && (
            <div className="permission-overlay" style={{
              display: "flex",
              flexFlow: "column",
              alignItems: "space-between",
              justifyContent: "center",
              color: "white",
              fontSize: "1rem",
              position: "fixed",
              padding: "15px 30px",
              textAlign: "center",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "radial-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.5))",
              backdropFilter: "blur(5px) saturate(130%)",
              zIndex: 99999
            }}>
              <div
                style={{
                  width: "85px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: "2rem",
                  color: "rgba(255,255,255,0.85)",
                  margin: "10px auto"
                }}
              >
                <FontAwesomeIcon icon={faVideo} />
                <FontAwesomeIcon icon={faMicrophone} />
              </div>
              <h3>Permitir acesso à câmara e ao microfone?</h3>
              <p style={{ marginTop: "-5px" }}>
                Para poder realizar a chamada, conceda as permissões para utilizar a câmara e o microfone do seu dispositivo.
              </p>
            </div>
          )}
        </AppContext.Provider>
      </ThemeProvider>
    );
  }
}

export default withNamespaces()(AidaClient);
