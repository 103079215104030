import React, { Component, Fragment } from 'react'
import styled from "styled-components";
import { injectGlobal } from "styled-components";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faPaperclip, faThumbsUp, faThumbsDown } from "@fortawesome/fontawesome-free-solid";

injectGlobal`
  #aida-mount .text-message a {
    color: ${({ theme, outbound, isBot }) =>
      outbound ? theme.white : isBot ? theme.darkGray : theme.white} !important;
    text-decoration: underline !important;
    font-weight: 700 !important;
    transition: opacity 150ms ease;
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      opacity: 0.8;
    }
  }

  #aida-mount .aida-quickaction {
    cursor: pointer;
    display: inline-block;
    padding: 3px 4px;
    border-radius: 3px;
    background: rgba(0,0,0,0.1);
    font-weight: 900;
    margin: 3px 0;
    transition: background 150ms ease;
    &:hover,
    &:active,
    &:focus {
      background: rgba(0,0,0,0.15);
    }
  }

  @-webkit-keyframes fade_in_out {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.25;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade_in_out {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.25;
    }
    100% {
      opacity: 1;
    }
  }
  .waiting_bubble {
    display: flex;
    position: relative;
    width: 80px;
    height: 2.4375rem;
    align-items: center;
    justify-content: space-around;
    border-radius: 8px;
    padding: 6px 12px;
    background-color: #e4e8e9;
  }
  .waiting_bubble__circle {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #bbb;
    -webkit-animation: fade_in_out 1.5s infinite;
            animation: fade_in_out 1.5s infinite;
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
  }
  .waiting_bubble__circle:first-of-type {
    margin-left: 10px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }
  .waiting_bubble__circle:last-of-type {
    margin-right: 10px;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
`;

const MasterContainer = styled.div`
  margin: 6px 0;
  word-wrap: break-word;
  width: 100%;
`;

const MessageItem = styled.div`
  font-size: 16px;
  line-height: 1.4375rem;
  border-radius: 8px;
  padding: 12px;
  width: 90%;

  font-size: 14px;

  color: ${({ theme, outbound, isBot }) =>
    outbound ? theme.white : isBot ? theme.darkGray : theme.white};
  background-color: ${({ theme, outbound, isBot }) =>
    outbound ? theme.primaryColor : isBot ? theme.lightGray : theme.darkGray};
  a {
    color: ${({ theme, outbound, isBot }) =>
      outbound ? theme.white : isBot ? theme.darkGray : theme.white} !important;
    text-decoration: underline !important;
    font-weight: 700 !important;
    transition: opacity 150ms ease;
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      opacity: 0.8;
    }
  }
  p, div, span, section {
    margin: 0;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: ${({ isBot, outbound }) => !!outbound ? "row-reverse" : 'row'};
  align-items: stretch;
  max-width: 100%;
`;

const ThumbsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  heigth: 100%;
`;

const thumbButtonColorSolver = (theme, color) => {
  if (color === 'green') {
    return theme.green;
  }

  if (color === 'red') {
    return theme.errorColor;
  }

  return color;
}

const ThumbButton = styled.button`
  display: flex
  background: none;
  border: none;
  cursor: pointer;

  &:focus {
    border: none;
  }
  & > svg {
    width: 1em !important;
    height: 1em !important;
    color: ${({ theme, color, isChosen}) => {
      if (isChosen === true) {
        return thumbButtonColorSolver(theme, color);
      }

      return "black";
    }};

    & > path {
      /* Podesse ter de mudar isto depois dependendo de como implementar-mos os likes e dislikes */
      opacity: ${({isLiked, isDisliked}) => {
        return (isLiked || isDisliked) ? "0.3" : "0.3";
      }}
    }

    @media all and (max-width: 600px) {
      width: 1.5em !important;
      height: 1.5em !important;
    }
  }

  &:hover > svg {
    color: ${({ theme, color }) => thumbButtonColorSolver(theme, color)};
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 6px;
  cursor: default;
  gap: 16px;
`;

const UserName = styled.span`
  font-weight: 900;
  display: block;
  font-size: 15px;
  line-height: 15px;
  cursor: default;
  color: ${({theme}) => theme.black};
`;

const UserImg = styled.img`
  --image-size: 24px;
  display: block;
  height: var(--image-size);
  width: var(--image-size);
  border-radius: var(--image-size);
  background: #e9e9e9;
  border: 0;
  outline: 0;
  cursor: default;
  overflow: hidden;
  box-shadow: 0 2px 4px -2px rgba(0,0,0,0.275);
  object-fit: cover;
`

const MessageTime = styled.span`
  font-weight: 200;
  display: block;
  font-size: 13px;
  line-height: 13px;
  // text-align: right;
  color: #000;
  cursor: help;
`

const SeparatorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 16px;
  color: lightgray;
  & > * {
    margin: 0;
  }
`;

const SeparatorLine = styled.div`
  height: 1px;
  border: 1px solid lightgray;
  flex: 1;
`;

const WarningContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({theme}) => theme.lightAlertColor};
  color: white;
  font-size: 14px;
  border-radius: 8px;
  padding: 8px;

  margin-top: 8px;
  margin-bottom: 8px;
  & > * {
    margin: 0;
    width: fit-content;
  }
`;

const customTrim = (s, c) => {
  if (c === "]") c = "\\]";
  if (c === "\\") c = "\\\\";
  return s.replace(new RegExp(
    "^[" + c + "]+|[" + c + "]+$", "g"
  ), "");
}

export default class Message extends Component {
  state = {
    chosenFeedback: null
  }

  urlify = text => {
    var urlMarkdownRegex = /\[(.*?)\]\((.*?)\)/g;
    if (urlMarkdownRegex.test(text)) {
      return text.replace(urlMarkdownRegex, function(match, link_text, url) {
        return '<div style="display: inline;"><a target="_blank" href="' + url + '">' + link_text + "</a></div>";
      });
    }

    var urlRegex = /((http|https)?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
      return '<div style="display: inline;"><a target="_blank" href="' + url + '">' + url + "</a></div>";
    });
  };

  clickHandler = (e) => {
    const { appendMessage } = this.props;
    // `target` is the element the click was on (the div we hooked or an element
    // with in it), `currentTarget` is the div we hooked the event on
    const el = e.target.closest(".aida-quickaction");
    if (el && e.currentTarget.contains(el)) {
        const trimedAction = customTrim(el.innerText.trim(),"\"");
        appendMessage({
          text:trimedAction,
          outbound: true,
          sentAt: new Date()
        });
    }
  }

  _giveFeedback = (msg, type) => {
    if (type == this.state.chosenFeedback) {
      return;
    }

    const { handleAiMessageFeedback } = this.props

    handleAiMessageFeedback(msg, type);
    this.setState({ chosenFeedback: type });
  }

  render() {
    const { message, imageUrl, botName='Robot', apiUrl } = this.props;
    const messageDate = message.sentAt ? new Date(message.sentAt) : null;

    const parsedTime = messageDate ? messageDate.getHours().toString().padStart(2, '0') + ":" + messageDate.getMinutes().toString().padStart(2, '0') : null;
    const elapsedParsedTime = messageDate ? messageDate.getFullYear() + '-' + (messageDate.getMonth() + 1).toString().padStart(2, '0') + '-' + messageDate.getDate().toString().padStart(2, '0') + ' ' + parsedTime : null;

    const normalizedImage = (
      imageUrl
        ? imageUrl
        : "https://image.flaticon.com/icons/svg/682/682037.svg"
    );


    if (this.props.isSeparator) {
      return (
        <SeparatorContainer>
          <SeparatorLine />
          <p>fim de conversa</p>
          <SeparatorLine />
        </SeparatorContainer>
      );
    }

    if (this.props.isWarning) {
      return (
        <WarningContainer>
          {/* <IconContainer

            className="unavailable"
            >
            <FontAwesomeIcon color={"white"} icon={faExclamationTriangle} />
          </IconContainer> */}
          <p>{message.text}</p>
        </WarningContainer>
      )
    }

    return (
      <MasterContainer>
        <UserInfo
          style={{
            flexDirection: message.outbound ? "row-reverse" : "row",
          }}
        >
          {!message.outbound && <UserImg src={message.isBot ? normalizedImage : message.operatorUserPhoto} alt={message.isBot ? botName : message.operatorUserName} /> }
          <UserName
            children={message.outbound ? "Eu" : message.isBot ? botName : message.operatorUserName}
          />
          {messageDate && (<MessageTime children={parsedTime} title={elapsedParsedTime}/>)}
        </UserInfo>
        {message.text == '[waiting_for_bot]' && (
          <div className="text-message">
            <div className="waiting_bubble">
              <div className="waiting_bubble__circle"></div>
              <div className="waiting_bubble__circle"></div>
              <div className="waiting_bubble__circle"></div>
            </div>
          </div>
        )}

        {!message.fileUrl && message.text != '[waiting_for_bot]' && (
          <MessageContainer
            isBot={message.isBot}
            outbound={message.outbound}
          >
            <MessageItem className="text-message" outbound={message.outbound} isBot={message.isBot} onClick={this.clickHandler} dangerouslySetInnerHTML={{
              __html: this.urlify(
                message.text
                            .replace(/(?:\r\n|\r|\n|\n)/g, "<br>")
                            .replace(/(\<script\>)/g, "\<script\>")
                            .replace(/(\<\/script\>)/g, "\<\/script\>")
                            .replace(/(\<action\>)/g, "\<span class='aida-quickaction'>")
                            .replace(/(\<\/action\>)/g, "\<\/span\>")
              )}}
            />
            <ThumbsContainer>
              { message.isBot && message.wireAiAssistant && (
                <Fragment>
                  <ThumbButton
                    color="green"
                    onClick={() => {
                      this._giveFeedback(message, 'useful')
                    }}
                    isChosen={this.state.chosenFeedback == 'useful'}
                  >
                    <FontAwesomeIcon
                      icon={faThumbsUp}
                    />
                  </ThumbButton>
                  <ThumbButton
                    color="red"
                    onClick={() => {
                      this._giveFeedback(message, 'useless')
                    }}
                    isChosen={this.state.chosenFeedback == 'useless'}
                  >
                    <FontAwesomeIcon
                      icon={faThumbsDown}
                    />
                  </ThumbButton>
                </Fragment>
              )}
            </ThumbsContainer>
          </MessageContainer>
        )}

        {message.fileUrl && (
          <MessageItem className="text-message" outbound={message.outbound} isBot={message.isBot} onClick={this.clickHandler} >
            <div><a target="_blank" href={apiUrl + message.fileUrl}><FontAwesomeIcon icon={faPaperclip}/> {message.text}</a></div>
          </MessageItem>
        )}
      </MasterContainer>
    )
  }
}
