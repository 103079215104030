import React, { Component } from "react";
import styled from "styled-components";

import Message from "./Message.js";

import { AppContext } from "./index.js";
import ConnError from "./ConnError.js";


const Container = styled.div`
  width: 100%;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  min-height: 0;
  flex-grow: 1;

  &:focus {
    outline: none;
  }

`;

const StyledList = styled.div`
  bottom: 70px;
  padding-left: 8px;
  padding-right: 8px;
  overflow-y: auto;
  scroll-behavior: smooth;


  &::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    background-color: ${({ theme }) => theme.white};
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${({ theme }) => theme.lightGray};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.lightGray};
  }

  @media all and (max-width: 600px) {
    padding: 0;
    padding-bottom: 16px;
  }
`;

const MsgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.outbound ? "flex-end" : "flex-start")};
`;

export default () => (
  <AppContext.Consumer>
    {context => {
      if (!context.isOpen) {
        return null;
      }

      return <Messages {...context} />;
    }}
  </AppContext.Consumer>
);

class Messages extends Component {
  // As props veem do AppContext.Cosumer
  _listRef = div => {
    this.scrollContainer = div;
  };

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.messages.length < this.props.messages.length) {
      return true;
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot) {
      this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight;
    }
  }

  render() {
    const { error, messages, botName, imageUrl, appendMessage, apiUrl, waitingForBotReply, handleAiMessageFeedback, language } = this.props;
    return (
      <React.Fragment>
        <Container>
          <StyledList innerRef={this._listRef}>
            {messages.map((msg, index) => (
              <MsgContainer key={`msg-${index}`} outbound={msg.outbound}>
                <Message
                  outbound={msg.outbound}
                  message={msg}
                  botName={botName}
                  imageUrl={imageUrl}
                  apiUrl={apiUrl}
                  appendMessage={appendMessage}
                  handleAiMessageFeedback={handleAiMessageFeedback}
                  isSeparator={msg.isSeparator}
                  isWarning={msg.isWarning}
                  language={language}
                />
              </MsgContainer>
            ))}
            {waitingForBotReply && (
              <MsgContainer id={`thinking`} key={`idle`} outbound={false}>
                <Message
                  outbound={false}
                  message={{isBot: true, sentAt: null, outbound: false, text: '[waiting_for_bot]'}}
                  botName={botName}
                  imageUrl={imageUrl}
                />
              </MsgContainer>
            )}
          </StyledList>
        </Container>
        <ConnError>{error}</ConnError>
      </React.Fragment>
    );
  }
}
