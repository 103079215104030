class AudioRecording {
  constructor() {
    this.mediaRecorder = null;
    this.pending_actions = [];
  }

  // navigator.mediaDevices
  setup(mediaDevices, stop_callback) {
    const constraints = { audio: true };
    let chunks = [];

    let onSuccess = function(stream) {
      const options = {
        audioBitsPerSecond: 12000 * 2,
      };
      this.mediaRecorder = new MediaRecorder(stream, options);

      // visualize(stream);


      this.mediaRecorder.onstop = function(e) {
        console.log("data available after MediaRecorder.stop() called.");

        const blob = new Blob(chunks, { 'type' : 'audio/ogg; codecs=opus' });
        chunks = [];
        const audioURL = window.URL.createObjectURL(blob);
        console.log("recorder stopped");
        stop_callback(blob, audioURL);
      }

      this.mediaRecorder.ondataavailable = function(e) {
        chunks.push(e.data);
      }

      if (this.pending_actions.length > 0) {
        for (let idx = 0; idx < this.pending_actions.length; idx++) {
          const action = this.pending_actions[idx];
          this[action]();
        }
      }
    }

    let onError = function(err) {
      console.log('The following error occured: ' + err);
    }

    mediaDevices.getUserMedia(constraints).then(onSuccess.bind(this), onError.bind(this));
  }

  start() {
    if(!this.mediaRecorder) {
      this.pending_actions.push('start');
      return;
    }
    this.mediaRecorder.start();
    console.log(this.mediaRecorder.state);
    console.log("recorder started");
  }

  stop() {
    if(!this.mediaRecorder) {
      this.pending_actions.push('start');
      return;
    }
    this.mediaRecorder.stop();
    console.log(this.mediaRecorder.state);
    console.log("recorder stopped");
  }
}

module.exports = AudioRecording;