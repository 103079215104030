import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { withNamespaces } from "react-i18next";
import i18n from "../i18n-react";
import languageData from '../../../LanguageData';
import io from "socket.io-client";

import deFlag from "./assets/flags/de.png";
import enFlag from "./assets/flags/en.png";
import esFlag from "./assets/flags/es.png";
import frFlag from "./assets/flags/fr.png";
import hiFlag from "./assets/flags/hi.png";
import itFlag from "./assets/flags/it.png";
import ptFlag from "./assets/flags/pt.png";
import ruFlag from "./assets/flags/ru.png";
import ukFlag from "./assets/flags/uk.png";
import zhFlag from "./assets/flags/zh.png";

const languagePngs = {
  de: deFlag,
  en: enFlag,
  es: esFlag,
  fr: frFlag,
  hi: hiFlag,
  it: itFlag,
  pt: ptFlag,
  ru: ruFlag,
  uk: ukFlag,
  zh: zhFlag,
};

const isDev = process.env.NODE_ENV === "development";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${spin} 1s linear infinite;
`;

const LanguageContainer = styled.div`
  position: absolute;
  right: 0px;
  width: 50%;
  bottom: 0 !important;
  display: flex;
  flex-direction: column;
  height: calc(100% - 45px - 16px);
  justify-content: center;
  align-items: center;
  background: #082283;
  border-radius: 10px;
  z-index: 35;
  margin: 5px;

  @media all and (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`;

const FlagGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  max-height: calc(100% - 45px - 16px);
  overflow-y: auto;
  padding: 10px;
`;

const FlagButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100px;
    height: 60px;
    border: ${(props) => (props.selected ? "2px solid white" : "none")};
    border-radius: 5px;
  }

  p {
    color: #fff;
    margin-top: 5px;
    text-align: center;
  }
`;

const AcceptButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px !important;
  background-color: #004ecb;
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

class LanguagePicker extends Component {
  state = {
    language: null,
    loading: false,
    translatedTexts: null,
  };

  socketEmit = (msg, data) => {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }

    this.socket = io(`${this.props.apiUrl}/client`, { path: "" });

    if (process.env.NODE_ENV === "development" || localStorage.debugWireChat == 'true') {
      console.log("\x1b[33m%s\x1b[0m\n", "====================================\nSocket => EMIT => " + msg + ":\n", data);
      console.log("\x1b[33m%s\x1b[0m", "====================================");
    }

    this.socket.emit(msg, data);
  };

  handleTermsTexts = (termsCurrent) => {
    if (!termsCurrent) return null;

    const { text, term_items } = termsCurrent;
    const formattedTermItems = {};
    term_items.forEach((item, index) => {
      formattedTermItems[index] = {
        description: item.description,
        title: item.title
      };
    });

    return {
      text: text,
      term_items: formattedTermItems
    };
  };

  handleLanguageSelection = (lang) => {
    this.setState({ language: lang, loading: true });
    const { termsCurrent, tenant, onTranslatedTexts, presentationMsg } = this.props;

    i18n.changeLanguage(lang, (err) => {
      if (err) return console.log("erro a mudar idioma: ", err);
      if (lang == 'pt'){
        this.setState({ loading: false });
        return;
      }

      this.socketEmit("translate-text", {
        texts: {
          termsTexts: this.handleTermsTexts(termsCurrent),
          introText: presentationMsg,
        },
        tenant: tenant,
        language: lang
      });

      this.socket.on("translated-text", (translatedTexts) => {
        if (onTranslatedTexts) {
          onTranslatedTexts(translatedTexts);
        }

        this.setState({ translatedTexts, loading: false });
      });
    });
  };

  render() {
    const { onSelectLanguage, t, accountLanguages } = this.props;
    const { language, loading } = this.state;

    const LANGUAGE_MAP = Object.keys(languageData).reduce((acc, key) => {
      if (accountLanguages.includes(key)) {
        acc[key] = languageData[key].text;
      }
      return acc;
    }, {});

    return (
      <LanguageContainer>
        <h2 style={{ color: "#fff" }}>{t("ai.pickLanguage")}</h2>
        <FlagGrid>
          {Object.entries(LANGUAGE_MAP).map(([langCode, langName]) => {
            let flagUrl = isDev
              ? this.props.apiUrl + languagePngs[langCode].replace(`/${langCode}`, `/aida/cdn/aida-client-ai/${langCode}`)
              : this.props.apiUrl + languagePngs[langCode].replace(`${langCode}`, `/aida/cdn/aida-client-ai/${langCode}`);

            return (
              <FlagButton
                key={langCode}
                onClick={() => this.handleLanguageSelection(langCode)}
                selected={language === langCode}
              >
                <img
                  src={flagUrl}
                  alt={langName}
                />
                <p>{langName}</p>
              </FlagButton>
            );
          })}
        </FlagGrid>
        {language && (
          <AcceptButton
            onClick={() => onSelectLanguage(language)}
            disabled={loading}
          >
            {loading ? <Spinner /> : t("ai.accept")}
          </AcceptButton>
        )}
      </LanguageContainer>
    );
  }
}

export default withNamespaces()(LanguagePicker);