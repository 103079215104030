import React from "react";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faUserCircle } from "@fortawesome/fontawesome-free-solid";
import { IconContainer } from "./StyledComponents";
import styled from "styled-components";

const OperatorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &.linear {
    flex-direction: row;
    justify-content: center;
    padding: 8px;
    border-bottom: 1px solid gray;
    background-color: #FAFAFA
  }
`;

/**
 *  Componente que representa a mensagem de estado atual dos operadores. Aqui está encapsuladoas mensagens de: 
 *  - Não temos operadores;
 *  - Temos n Operadores;
 */
class Operators extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let { numberOfOperators, onClick, linear, isTalkingWithOperator, botIsActive } = this.props;

    let message = "";
    let ctaText = "";
    let icon = faExclamationTriangle;
    let layout = linear ? "linear" : "";

    if (isTalkingWithOperator) {
      // FAzer nada é só para fazer curto circuito
      icon = faUserCircle;
      message = `Está a conectado com o operador.`
      ctaText = "";

      if (botIsActive) {
        message += " Mas o mesmo ativou por um bocado as funcionalidades de IA."
      }

    } else if (numberOfOperators === 0) {
      message = "Não existem operadores disponíveis neste momento.";
      ctaText = "Enviar uma mensagem";
    } else if (numberOfOperators === 1){
      message = "Existe um operador disponível neste momento.";
      ctaText = "Falar com o operador";
      icon = faUserCircle
    } else {
      message = `Existem ${numberOfOperators} operadores disponíveis neste momento.`;
      ctaText = "Falar com o operador";
      icon = faUserCircle
    }

    return(
      <OperatorsContainer
        className={layout}
        >
        <IconContainer
          className="unavailable"
          >
          <FontAwesomeIcon icon={icon} />
        </IconContainer>
        {message}
        <span
          onClick={onClick || (() => {})}
          className={numberOfOperators === 0 ? "link unavailable" : "link"}
        >
          {ctaText}
        </span>
      </OperatorsContainer>
    )
  }
}

export default Operators;