module.exports = {
  'pt': {
    text: 'Português',
    display: 'PORTUGUÊS DE PORTUGAL',
    voice: 'pt-PT-Wavenet',
    suffix: {
      female: '-D',
      male: '-C'
    },
    numbers: {
      '0': 'zero',
      '1': 'um',
      '2': 'dois',
      '3': 'três',
      '4': 'quatro',
      '5': 'cinco',
      '6': 'seis',
      '7': 'sete',
      '8': 'oito',
      '9': 'nove',
    }
  },
  'en': {
    text: 'English',
    display: 'INGLÊS BRITÂNICO',
    voice: 'en-GB-Wavenet',
    suffix: {
      female: '-C',
      male: '-D'
    },
    numbers: {
      '0': 'zero',
      '1': 'one',
      '2': 'two',
      '3': 'three',
      '4': 'four',
      '5': 'five',
      '6': 'six',
      '7': 'seven',
      '8': 'eight',
      '9': 'nine',
    }
  },
  'fr': {
    text: 'Français',
    display: 'FRANCÊS',
    voice: 'fr-FR-Wavenet',
    suffix: {
      female: '-C',
      male: '-D'
    },
    numbers: {
      '0': 'zéro',
      '1': 'un',
      '2': 'deux',
      '3': 'trois',
      '4': 'quatre',
      '5': 'cinq',
      '6': 'six',
      '7': 'sept',
      '8': 'huit',
      '9': 'neuf',
    }
  },
  'es': {
    text: 'Español',
    display: 'ESPANHOL DE ESPANHA',
    voice: 'es-ES-Wavenet',
    suffix: {
      female: '-D',
      male: '-B'
    },
    numbers: {
      '0': 'cero',
      '1': 'uno',
      '2': 'dos',
      '3': 'tres',
      '4': 'cuatro',
      '5': 'cinco',
      '6': 'seis',
      '7': 'siete',
      '8': 'ocho',
      '9': 'nueve',
    }
  },
  'de': {
    text: 'Deutsch',
    display: 'ALEMÃO',
    voice: 'de-DE-Wavenet',
    suffix: {
      female: '-C',
      male: '-D'
    },
    numbers: {
      '0': 'null',
      '1': 'eins',
      '2': 'zwei',
      '3': 'drei',
      '4': 'vier',
      '5': 'fünf',
      '6': 'sechs',
      '7': 'sieben',
      '8': 'acht',
      '9': 'neun',
    }
  },
  'it': {
    text: 'Italiano',
    display: 'ITALIANO',
    voice: 'it-IT-Wavenet',
    suffix: {
      female: '-B',
      male: '-C'
    },
    numbers: {
      '0': 'zero',
      '1': 'uno',
      '2': 'due',
      '3': 'tre',
      '4': 'quattro',
      '5': 'cinque',
      '6': 'sei',
      '7': 'sette',
      '8': 'otto',
      '9': 'nove',
    }
  },
  'uk': {
    text: 'Українська',
    display: 'UCRANIANO',
    voice: 'uk-UA-Wavenet',
    suffix: {
      female: '-A',
      male: '-A'
    },
    numbers: {
      '0': 'нуль',
      '1': 'один',
      '2': 'два',
      '3': 'три',
      '4': 'чотири',
      '5': 'пʼять',
      '6': 'шість',
      '7': 'сім',
      '8': 'вісім',
      '9': 'девʼять'
    }
  },
  'ru': {
    text: 'Русский',
    display: 'RUSSO',
    voice: 'ru-RU-Wavenet',
    suffix: {
      female: '-C',
      male: '-D'
    },
    numbers: {
      "0": "ноль",
      "1": "один",
      "2": "два",
      "3": "три",
      "4": "четыре",
      "5": "пять",
      "6": "шесть",
      "7": "семь",
      "8": "восемь",
      "9": "девять"
    }
  },
  'hi': {
    text: 'हिन्दी',
    display: 'HINDI',
    voice: 'hi-IN-Wavenet',
    suffix: {
      female: '-D',
      male: '-C'
    },
    numbers: {
      "0": "शून्य",
      "1": "एक",
      "2": "दो",
      "3": "तीन",
      "4": "चार",
      "5": "पाँच",
      "6": "छह",
      "7": "सात",
      "8": "आठ",
      "9": "नौ"
    }
  },
  'zh': {
    text: '简体中文',
    display: 'CHINÊS SIMPLIFICADO',
    voice: 'cmn-TW-Wavenet',
    suffix: {
      female: '-A',
      male: '-C'
    },
    numbers: {
      "0": "零",
      "1": "一",
      "2": "二",
      "3": "三",
      "4": "四",
      "5": "五",
      "6": "六",
      "7": "七",
      "8": "八",
      "9": "九"
    }
  }
};