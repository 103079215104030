import styled, { css, injectGlobal } from "styled-components";
import posed from "react-pose";
import { spring } from "popmotion";

// Estilos globais que ficam presentes directamente na stylesheet
injectGlobal`
  @import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap");
  #aida-mount {
    box-sizing: border-box;
    font-family: "Nunito Sans", "NunitoSans", sans-serif;
  }
  #aida-mount * {
    font-family: "Nunito Sans", "NunitoSans", sans-serif;
  }
  video {
    background-color: black;
    transition: all 250ms ease;
  }
  video.connected {
    position: absolute;
    height: 90px !important;
    bottom: 8px;
    right: 8px;
    border-radius: 4px;
    box-shadow: 0px 4px 12px rgba(0,0,0,0.8);
  }
  .react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
  }

  .react-contextmenu-wrapper {
    display: inline-block;
  }

  .react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
  }

  .react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
    cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
  }

  .react-contextmenu-item svg {
    margin-right: 10px;
  }

  .react-contextmenu-item.react-contextmenu-item--active,
  .react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
  }

  .react-contextmenu-item.react-contextmenu-item--disabled,
  .react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
    color: #878a8c;
  }

  .react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
  }

  .react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
  }

  .react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
  }

  .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px;
  }

  .example-multiple-targets::after {
    content: attr(data-count);
    display: block;
  }
`;

// Componente para mostrar o avatar, nome do operador e botão de fechar
export const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px 8px 24px;
  min-height: 45px;
  font-weight: bold;
  z-index: 30;
  background: #082283;
  color: #fff;

  /* Variante mais clara da cor do background do header */
  border: 0.5px solid #0a1b59
  border-radius: 2px 2px 0 0;

  /* box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.125); */
  @media only screen and (hover: none) and (pointer: coarse) and (max-width: 600px) {
    // box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.175);
  }
  &.no-margin {
    margin-bottom: 0;
  }
  &.auto-margin {
    margin-bottom: auto;
  }
  .header-name {
    display: block;
    /* height: 21px; */
  }
  /* A imagem foi comentada por isso isto é inútil */
  .header-picture {
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 8px;
    border-radius: 30px;
    overflow: hidden;
    margin-left: -4px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.33);
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

// Componente container que define o chat principal
export const Container = styled.div`
  right: 0%;
  bottom: 0px;
  max-width: 725px;
  z-index: 9999;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  margin: 0px 0px 0px 0px;
  width: calc(100% - 16px);
  height: 0;
  padding: 1rem;
  max-height: 750px;

  &.has-video {
    height: 100% !important;
  }

  @media only screen and (hover: none) and (max-width: 600px) {
    // Mobile apenas
    width: 100%;
    max-height: 100%;
    padding: 0;
    margin: 0;
    border-radius: 0 !important;
  }

  &.container-open {
    height: 100% !important;

    @media all and (min-width: 600px) {
      height: calc(100% - 16px)  !important;
    }
  }

  @media all and (min-width: 600px) {
    // height: calc(100% - 16px)  !important;
    bottom: 16px;
    right: 16px;
  }

  @media all and (max-width: 600px) {
    max-width: 100%;
  }
`;

// Poses do container do chat principal
export const SlabAnim = posed.div({
  open: {
    bottom: "0",
    opacity: 1,
    height: "100%",
    transition: (props) =>
      spring({ ...props, stiffness: 330, mass: 0.1, damping: 18 }),
  },
  closed: {
    bottom: "4px",
    height: "80px",
    opacity: 0,
    transition: (props) =>
      spring({ ...props, stiffness: 330, mass: 0.1, damping: 18 }),
  },
  firstTime: {
    bottom: '4px',
    height: '80px',
    opacity: 1,
    transition: (props) =>
      spring({ ...props, stiffness: 330, mass: 0.1, damping: 18 }),
  },
  hovered: {
    bottom: "4px",
    height: "80px",
    opacity: 1,
    transition: (props) =>
      spring({ ...props, stiffness: 330, mass: 0.1, damping: 18 }),
  },
});

// Container do chat principal
export const Slab = styled(SlabAnim)`
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.white};
  overflow: hidden;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16), 0 2px 6px rgba(0, 0, 0, 0.18);
  border-radius: 2px;
  @media all and (min-width: 481px) {
    max-width: 725px;

    /* Isto parece magia negra, não sei se é boa prática, mas funciona para o que se quer */
    .container-closed & {
      max-width: 350px;
    }
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 4px 7px rgba(0, 0, 0, 0.18);
  }
  @media only screen and (hover: none) and (pointer: coarse) and (max-width: 600px)  {
    // Mobile only
    border-radius: 0 !important;
  }
  .video-assistante-btn {
    position: absolute;
    top: 9px;
    right: 69px;
    display: block;
    width: 28px;
    height: 28px;
    background: ${({ theme }) => theme.primaryColor};
    color: white;
    border-radius: 32px;
    z-index: 2000;
    font-size: 0;
    text-indent: -99999px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 120ms ease;
    svg {
      height: 14px;
      width: 14px;
      opacity: 0.85;
      fill: white;
      path {
        fill: white;
      }
    }
    &.hide {
      display: none !important;
    }
    &:hover,
    &:focus {
      transform: scale(1.1);
    }
    &:active {
      transform: scale(1.075);
    }
  }
  .video-assistante-btn.video-assistante-on {
    background: red;
  }
  .videoAssistant {
    background-size: 380px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
  }
  .speech-to-text-btn {
    position: absolute;
    top: 9px;
    right: 39px;
    display: block;
    width: 28px;
    height: 28px;
    background: ${({ theme }) => theme.primaryColor};
    color: white;
    border-radius: 32px;
    z-index: 2000;
    font-size: 0;
    text-indent: -99999px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 120ms ease;
    svg {
      height: 14px;
      width: 14px;
      opacity: 0.85;
      fill: white;
      path {
        fill: white;
      }
    }
    &.hide {
      display: none !important;
    }
    &:hover,
    &:focus {
      transform: scale(1.1);
    }
    &:active {
      transform: scale(1.075);
    }
  }
  .speech-to-text-btn.recognizing {
    background: green;
  }
  .close-btn {
    position: absolute;
    top: 9px;
    right: 9px;
    display: block;
    width: 28px;
    height: 28px;
    background: white; /* ${({ theme }) => theme.gray} */
    color: #082283;
    border-radius: 32px;
    z-index: 2000;
    font-size: 0;
    text-indent: -99999px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 120ms ease;
    svg {
      height: 12px;
      width: 12px;
      opacity: 0.85;
      fill: #082283;
      path {
        fill: #082283;
      }
    }
    &.hide {
      display: none !important;
    }
    &:hover,
    &:focus {
      transform: scale(1.1);
    }
    &:active {
      transform: scale(1.075);
    }
  }
`;

// Componente do título de boas vindas
export const SlabTitle = styled.div`
  margin-left: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.darkGray};
`;

// Componente do subtítulo de boas vindas
export const SlabSubTitle = styled.div`
  margin-left: 20px;
  color: ${({ theme }) => theme.darkGray};
`;

// Poses da confirmação de chamada
export const VideoConfirmationPosed = posed.div({
  exit: {
    y: "100%",
    transition: (props) =>
      spring({ ...props, stiffness: 20, mass: 0.05, damping: 20 }),
  },
  enter: {
    y: 0,
    transition: (props) =>
      spring({ ...props, stiffness: 10, mass: 0.05, damping: 20 }),
  },
});

// Componente da confirmação de chamada (ecrã de atender)
export const VideoConfirmation = styled(VideoConfirmationPosed)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-50%, -50%);
  height: 100vh;
  margin: 0;
  padding: 50px 20px;
  z-index: 20000000000;
  color: #f1f2f6;
  background: rgba(87, 96, 111, 0.95);
  transition: background 2s ease-in-out, opacity 200ms ease;
  @media only screen and (pointer: fine) {
  }
  &.is-connecting {
    background: rgba(35, 161, 87, 0.95);
    @media only screen and (pointer: fine) {
    }
  }
  .main {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    display: block;
    margin: 45px 0 6px;
  }
  .descriptive {
    text-align: center;
    display: block;
    margin-bottom: 8px;
    padding: 0 16px;
    font-size: 16px;
    line-height: 18px;
    opacity: 0.85;
    strong {
      font-weight: 700;
      color: white;
    }
  }
  .status {
    text-align: center;
    display: block;
    margin: 6px 0;
    font-size: 14px;
    opacity: 0.85;
    font-weight: 700;
  }
  .operator-name {
    text-align: center;
    display: block;
    margin: 15px 0 30px;
    font-size: 26px;
    font-weight: 700;
  }
  .operator-pic-container {
    position: relative;
  }
  .operator-pic {
    position: relative;
    border-radius: 120px;
    height: 120px;
    width: 120px;
    box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.25);
    margin: 0 auto 30px;
    overflow: hidden;
    z-index: 3;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .call-signal {
    position: absolute;
    top: 0;
    left: calc(50% - 60px);
    z-index: 2;
    width: 120px;
    height: 120px;
    border-radius: 120px;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.6);
    animation: blobPulse 1750ms infinite;
    transition: all 800ms ease-in-out;
  }
  @keyframes blobPulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.6);
    }

    70% {
      transform: scale(1.2);
      box-shadow: 0 0 20px 4px rgba(255, 255, 255, 0.33);
    }

    100% {
      transform: scale(1);
      box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.6);
    }
  }
  .actions-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
  .chat-action {
    display: inline-block;
    padding: 10px 12px;
    border-radius: 10px;
    font-size: 22px;
    color: white;
    font-weight: 700;
    cursor: pointer;
    transition: all 150ms ease;
    svg {
      width: 24px !important;
    }
    &.accept {
      background: #25bb64;
    }
    &.reject {
      background: #ff401f;
    }
    &:hover,
    &:focus {
      opacity: 0.9;
    }
    &:active {
      opacity: 0.8;
    }
  }
`;

// Componente para mostrar ou chamar operadores
export const ActiveUsers = styled.div`
  margin-bottom: auto;
  display: block;
  padding: 12px 8px 8px;
  font-size: 14px;
  line-height: 18px;
  /*
  border: 1px solid #d9d9d9;

  border-radius: 10px;
  background: #fbfbfb;
  */
  z-index: 1;
  color: #666666;

  .link {
    display: inline-block;
    font-weight: 800;
    color: ${({ theme }) => theme.primaryColor};
    text-decoration: none;
    padding-bottom: 0;
    border-bottom: 2px solid ${({ theme }) => theme.primaryColor};
    transition: all 125ms ease;
    &:hover,
    &:active {
      cursor: pointer;
      color: ${({ theme }) => theme.secondaryColor};
      border-bottom: 2px solid ${({ theme }) => theme.secondaryColor};
    }
    &.unavailable {
      color: ${({ theme }) => theme.alertColor};
      border-bottom-color: ${({ theme }) => theme.alertColor};
      &:hover,
      &:active {
        color: ${({ theme }) => theme.lightAlertColor};
        border-bottom: 2px solid ${({ theme }) => theme.lightAlertColor};
      }
    }
  }
`;

// Componente do ícone do estado da disponibilidade dos operadores
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  float: left;
  height: 100%;
  width: 24px;
  margin-right: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.primaryColor};
  &.unavailable {
    color: ${({ theme }) => theme.alertColor};
  }
  svg {
    width: 32px !important;
    height: 32px !important;
  }
`;

// Componente container dos streams de vídeo da vídeochamada
export const VideoChatContainer = styled.div`
  position: relative;
  background: black;
  display: none;
  align-items: center;
  height: 100% !important;
  width: 100% !important;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  z-index: 2500;
  &.connected {
    display: flex;
    .remote-videos {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      justify-content: flex-start;
      gap: 8px;

      & > video {
        position: relative;
        flex: 1;
        display: block;
        max-height: 200px;
        min-width: 340px;
        aspect-ratio: 16 / 9 !important;
        border-radius: 10px;

        @media all and (max-width: 600px) {
          min-width: auto;
          max-width: 100%;
        }
      }

    }
    video,
    audio {
      z-index: 2500;
    }
  }
  &.userIsStreaming {
    .infoLabel {
      position: absolute;
      bottom: 100px;
      left: 8px;
      display: flex;
      align-items: center;
      height: 20px;
      width: 100px;
      padding: 2px 5px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      border-radius: 3px 3px 0 0;
      z-index: 3000;
      color: rgba(0,0,0,0.85);
      background: ${({ theme }) => theme.successColor};
    }
  }
`;

export const FloatingVideoButton = css`
  display: block;
  height: 26px;
  width: 30px;
  text-align: center;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-shadow: none;
  appearance: none;
  z-index: 2;
  background: rgba(241, 242, 246, 0.85);
  color: #2f3542;
  cursor: pointer;
  transition: all 150ms ease;
  @media only screen and (pointer: fine) {
    @supports (backdrop-filter: blur(5px)) {
      backdrop-filter: blur(5px) saturate(180%);
      background: rgba(241, 242, 246, 0.5);
    }
  }
  &:hover,
  &:focus {
    background: rgba(241, 242, 246, 0.65);
  }
  &:active {
    background: rgba(241, 242, 246, 0.75);
  }
  &:not(:last-child) {
    border-right: 1px solid rgba(47, 53, 66, 0.25);
  }
`;

// Componente dos controlos de áudio e vídeo do utilizador
export const LocalVideoControls = styled.div`
  padding: 4px;
  position: absolute;
  display: flex;
  top: 0;
  z-index: 3000;
  &.connected {
    top: unset;
    bottom: 10px;
    left: 10px;
  }
  > button,
  button {
    ${FloatingVideoButton}c
  }
`;

// Componente dos controlos de input, output e vídeo do utilizador
export const LocalVideoConfig = styled.div`
  display: inline-block;
  float: right;
  > button,
  button {
    ${FloatingVideoButton}
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
    bottom: 10px;
    background: rgba(255, 255, 255, 0.8) !important;
    border-radius: 4px;
    width: unset;
    padding: 0px 8px;
    height: 26px;
    svg {
      margin-right: 6px;
    }
    span {
      font-size: 14px;
      line-height: 13px;
    }
    &:hover,
    &:focus {
      background: rgba(255, 255, 255, 0.95) !important;
    }
    &:active {
      background: rgba(255, 255, 255, 0.7) !important;
    }
  }
  nav.react-contextmenu {
    .react-contextmenu-item--selected {
      background-color: ${({ theme }) => theme.primaryColor} !important;
      border-color: ${({ theme }) => theme.primaryColor} !important;
    }
  }
`;

// Componente container dos botões de controlo de chamada
export const CallButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
`;

// Estilo base para botões de controlo de chamada
export const InCallButton = css`
  background: ${({ theme }) => theme.darkGray};
  position: relative;
  border: 0;
  outline: 0;
  box-shadow: 0;
  z-index: 3000;
  height: 100%;
  flex: 1 0 auto;
  max-width: 50%;
  color: white !important;
  cursor: pointer;
  border-radius: 4px;
  transition: all 180ms ease;
  svg {
    margin-right: 2px;
    position: absolute;
    left: 10px;
    top: 8px;
  }
  &:hover,
  &:active,
  &:focus {
    transform: scale(1.05);
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

// Componente do botão para desligar a chamada
export const UserHangUp = styled.button`
  ${InCallButton}
  background: ${({ theme }) => theme.errorColor};
`;

// Componente do botão para partilhar o ecrã
export const UserShare = styled.button`
  ${InCallButton}
  background: ${({ theme }) => theme.primaryColor};
  // Mobile only
  @media only screen and (pointer: coarse) and (max-width: 600px)  {
    display: none !important;
  }
  @media only screen and (hover: none) {
    display: none !important;
  }
  &.userIsStreaming {
    background: ${({ theme }) => theme.successColor};
  }
`;

// Componente com o texto "BETA" para botão de interacção com a chamada
export const BetaTag = styled.span`
  display: block;
  padding: 3px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 9px;
  color: white;
  opacity: 0.8;
  font-weight: 800;
  letter-spacing: 1px;
`;

// Componente que se tiver mais do que um elemento dá split pelo meio com um separador a meio
export const SplitPanel = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  // height: 100%;
  min-height: 0;
  flex: 1;

  & > * {
    flex: 1;
    padding: 8px 16px 0em 16px;
    padding-top: 1.5em;
    min-height: 0;
  }

  /* TODO: Mudar o css deste componente para ser mobile first */
  @media all and (max-width: 600px) {
    flex-direction: column
    gap: 16px;
    & > * {
      padding: 0px 16px;
      min-height: auto;
    }
    & > :nth-child(1) {
      overflow: none;
      flex: 1;
    }
    & > :nth-last-child(1) {
      flex: 11;
    }
  }

  @media all and (min-width: 600px) {
    & > :nth-child(2)::before {
      --separator-spacing: 56px;

      content: '';
      position: absolute;
      height: calc(100% - var(--separator-spacing) * 2);
      top: var(--separator-spacing);
      left: 50%;
      transform: translate(-50%, 0);
      width: 1px;
      background-color: lightgray;
      border-radius: 1px;
    }
  }
`;