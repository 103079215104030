import React, { Component, Fragment } from 'react'
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faPhone, faPhoneSlash } from '@fortawesome/fontawesome-free-solid';
import { VideoConfirmation } from './StyledComponents';
import { PoseGroup } from 'react-pose';

export class VideoConfirmationLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    let { operatorUserInfo, incoming, accept, reject } = this.props;

    if (!operatorUserInfo) {
      return null;
    }

    if (this.props.connectingVideo) {
      return (
        <VideoConfirmation className="is-connecting">
          <span>
            <span className="operator-name">{operatorUserInfo.name}</span>
            <div className="operator-pic"><img src={operatorUserInfo.photo} alt="Operador" /></div>
            <span className="main">A conectar-se com o operador.</span>
            <span className="descriptive">Conceda as permissões necessárias para usar <strong>a câmara e o microfone</strong> para falar com o operador.</span>
            <span className="status">A estabelecer ligação...</span>
          </span>
        </VideoConfirmation>
      );
    }

    if (this.props.waitingForVideo && typeof operatorUserInfo !== "undefined" && operatorUserInfo !== null) {


      let main = "Realizar chamada com o operador?";
      let descriptive = (<Fragment>Conceda as permissões necessárias para usar <strong>a câmara e o microfone</strong> para falar com o operador.</Fragment>);
      let join = "Atender";
      let cancel = "Recusar";

      if (incoming) {
        main = "Juntar-se à chamada a decorrer?";
        descriptive = (<Fragment>Conceda as permissões necessárias para usar <strong>a câmara e o microfone</strong> para se juntar à conversa para a qual foi convidado.</Fragment>);
        join = "Juntar-se";
        cancel = "Cancelar";
      }

      return (
        <PoseGroup animateOnMount={true}>
          <VideoConfirmation>
            <span className="operator-name">{operatorUserInfo.name}</span>
            <div className="operator-pic-container">
              <div className="operator-pic"><img src={operatorUserInfo.photo} alt="Operador" /></div>
              <div className="call-signal"></div>
            </div>
            <span className="main"> {main}</span>
            <span className="descriptive">{descriptive}</span>
            <div className="actions-container">
              <span onClick={accept} className="chat-action accept">
                <FontAwesomeIcon icon={faPhone} /> {join}
              </span>&nbsp;&nbsp;
              <span onClick={reject} className="chat-action reject">
                <FontAwesomeIcon icon={faPhoneSlash} /> {cancel}
              </span>
            </div>
          </VideoConfirmation>
        </PoseGroup>
      )
    }

    return null;
  }
}

export default VideoConfirmationLayout;