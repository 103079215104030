import React, { Component } from 'react'
import styled from 'styled-components';

const Assistant = styled.video`
  --size: 300px;
  height: var(--size);
  width: var(--size);

  @media all and (max-width: 600px) {
    --size: 200px;
  }
`;

export class AiAssistant extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setupVideoAssistant = () => {
    let videoAssistant = document.getElementById('videoAssistant');
    let videoAssistantAudio = document.getElementById('videoAssistantAudio');

    if (!videoAssistant) {
      return;
    }
    if (this.props.language == 'pt'){
      videoAssistant.getElementsByTagName('source')[0].src = `${this.props.apiUrl}/ai/api/v1/avatar/${this.props.tenant}/videoAssistant?video=intro`;
      videoAssistant.loop = false;
      videoAssistant.muted = false;
      videoAssistant.load();
    } else {
      videoAssistant.getElementsByTagName('source')[0].src = `${this.props.apiUrl}/ai/api/v1/avatar/${this.props.tenant}/videoAssistant?video=texto`;
      videoAssistant.loop = true;
      videoAssistant.muted = true;
      videoAssistant.load();

      videoAssistantAudio.getElementsByTagName('source')[0].src = `${this.props.apiUrl}/ai/api/v1/avatar/${this.props.tenant}/videoAssistant?audio=intro-${this.props.language}.mp3`;
      videoAssistantAudio.loop = false;
      videoAssistantAudio.load();
    }

    videoAssistant.addEventListener('ended', () => {
      // console.log("Disparou o End do videoAssistant");
      this.props.setParentState({
        videoAssistantHasMadeIntroduction: true
      });

      videoAssistant.getElementsByTagName('source')[0].src = `${this.props.apiUrl}/ai/api/v1/avatar/${this.props.tenant}/videoAssistant?video=idle`;
      videoAssistant.loop = true;
      videoAssistant.load();
    }, false);

    if (!videoAssistantAudio) {
      return;
    }

    videoAssistantAudio.addEventListener('ended', () => {
      // console.log("Disparou o End do videoAssistantAudio");
      this.props.setParentState({
        videoAssistantHasMadeIntroduction: true
      });
      videoAssistant.getElementsByTagName('source')[0].src = `${this.props.apiUrl}/ai/api/v1/avatar/${this.props.tenant}/videoAssistant?video=idle`;
      videoAssistant.loop = true;
      videoAssistant.load();

      videoAssistantAudio.getElementsByTagName('source')[0].src = '';
      videoAssistant.load();
    }, false);
  }

  componentDidMount = () => {
    if(this.props.hasTerms) {
      document.addEventListener('wire_ai_terms_accepted', () => {
        this.setupVideoAssistant();
      });
    }
    else if (this.props.accountLanguages.length > 1){
      document.addEventListener('wire_ai_language_chosen', () => {
        this.setupVideoAssistant();
      });
    }
    else {
      this.setupVideoAssistant();
    }
  }

  render() {
    let { videoAssistantHasMadeIntroduction } = this.props;

    return (
      <React.Fragment>
        <Assistant
          style={{ backgroundSize: 'contain', backgroundImage: `url(${this.props.apiUrl}/ai/api/v1/avatar/${this.props.tenant}/image)` }}
          autoPlay
          id='videoAssistant'
          className='videoAssistant'
          width='100%'
          playsInline
        >
          <source src={`${this.props.apiUrl}/ai/api/v1/avatar/${this.props.tenant}/videoAssistant?video=idle`} type="video/mp4" />
        </Assistant>
        <audio
          autoPlay
          id='videoAssistantAudio'
          playsInline>
          <source src='' type="audio/mp3" />
        </audio>
      </React.Fragment>
    )
  }
}

export default AiAssistant
