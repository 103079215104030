import React from "react";
import ReactDOM from "react-dom";
import './i18n-react';

import AidaClient from "./components";

import { default as defaultTheme } from "./theme";

const init = (config = {}) => {
  const { theme, mountId, ...rest } = config;

  ReactDOM.render(
    <AidaClient
      {...{...rest, tenant: (rest.tenant||'').replaceAll('-','_')}}
      theme={{
        ...defaultTheme,
        ...theme
      }}
    />,
    document.getElementById(mountId || "aida-mount")
  );
};

export default { init };
